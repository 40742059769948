import React, { useState } from 'react';
import { TextInput, View, StyleSheet, TouchableOpacity } from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';

function GlobalLookup({ searchText, onSearchTextChange, onPress, onClearSearch }) {
  const [isFocused, setIsFocused] = useState(false);

  const clearSearchText = () => {
    onSearchTextChange(''); // Clear the search text
    onClearSearch(); // Call the onClearSearch function
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };


  return (
    <View style={styles.textItemLookupContainer}>
      <Ionicons name="ios-search" style={styles.searchIcon} size={20} />
      <TextInput
        style={styles.textItemLookup}
        placeholder={isFocused ? '' : 'Search & Add Item'}
        value={searchText}
        onChangeText={(text) => onSearchTextChange(text)}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {searchText ? (
        <TouchableOpacity onPress={clearSearchText}>
          <Ionicons name="ios-close" style={styles.closeIcon} size={24} color="blue" />
        </TouchableOpacity>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  textItemLookupContainer: {
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: 'gray',
    backgroundColor: 'lightgray',
    borderRadius: 20,
    paddingHorizontal: 10,
  },
  textItemLookup: {
    flex: 1,
    marginLeft: 10,
    fontSize: 16,
    textAlign: 'center',
  },
  searchIcon: {
    color: 'gray',
  },
  closeIcon: {
    marginLeft: 10,
  },
});

export default GlobalLookup;
