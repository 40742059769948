import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';

import ItemLookupButton from './ItemLookupButton';
import ItemListView from './ItemListView';
import ItemLookup from './ItemLookup';
import AllProducts from './AllProducts';
import GlobalLookup from './GlobalLookup';
import React, { useState } from 'react';

function LandingScreen() {
  const [searchText, setSearchText] = useState('');
  console.log(' SEARCH '+ searchText);

    return (
        <View style={styles.container}>
      
        {/* search view 
        Tab view
        List view       
   Navigation view */}
       <View style={styles.searchContainer}> 
       <GlobalLookup searchText={searchText} onSearchTextChange={setSearchText} />       
       </View>
       <View style={styles.listContainer}>
         <AllProducts searchText={searchText}/>
       </View>
      
       {/*<Text>Grocery Shopping Management</Text>
       <StatusBar style="auto" />*/}
     </View>
    );
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      // backgroundColor: '#fff',
      // alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: 1
    },
    searchContainer: {
      flex: 0.4,
      flexDirection: 'row'
    },
    listContainer: {
      flex: 4,
      borderWidth: 0.2,
      
    },
    navigationConatainer: {
      flex: 0.6,
      borderWidth: 0.2,
      borderColor: 'blue'
    }
  
  });

export default LandingScreen;