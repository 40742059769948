import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import LandingScreen from './LandingScreen';
import FavScreen from './FavScreen';
import FinalListScreen from './FinalListScreen';

import Ionicons from 'react-native-vector-icons/Ionicons';
import ItemListScreen from './ItemListScreen';
import FavListScreen from './FavListScreen';

const Tab = createBottomTabNavigator();

function BottomTabNavigator() {
  return (
    <Tab.Navigator
      initialRouteName="Grocery Catalog"
      screenOptions={({ route }) => ({
        tabBarShowLabel: true,
        headerShown: false,
      })}
      tabBarOptions={{
        activeTintColor: 'red', // Change the color of the active tab
        style: {
          backgroundColor: 'white', // Background color of the tab bar
        },
        labelStyle: {
          fontSize: 14, // Adjust the font size of the tab labels
          fontWeight: 'bold', // Set the font weight to bold
        },
      }}
    >


<Tab.Screen
        name="Grocery Catalog"
        component={FavListScreen}
        options={({ route }) => ({
          tabBarIcon: ({ color, size }) => (
            <Ionicons name={'ios-list'} size={size} color={color} />
          ),
        })}
      />

{/* <Tab.Screen
        name="Grocery Catalog"
        component={FavScreen}
        options={({ route }) => ({
          tabBarIcon: ({ color, size }) => (
            <Ionicons name={'ios-list'} size={size} color={color} />
          ),
        })}
      /> */}


      <Tab.Screen
        name="  List"
        component={ItemListScreen}
        options={({ route }) => ({
          tabBarIcon: ({ color, size }) => (
            <Ionicons name={route.name === 'List' ? 'list' : 'list-outline'} size={size} color={color} />
          ),
        })}
      />

      {/* <Tab.Screen
        name="Favorite"
        component={FavListScreen}
        options={({ route }) => ({
          tabBarIcon: ({ color, size }) => (
            <Ionicons
              name={route.name === 'Favorite' ? 'heart' : 'heart-outline'}
              size={size}
              color={color}
            />
          ),
        })}
      /> */}
    </Tab.Navigator>
  );
}

export default BottomTabNavigator;
