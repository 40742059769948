
import { Text, View, StyleSheet, FlatList, Image, Button,TouchableOpacity, Share, ScrollView, Alert } from 'react-native';
import {Picker} from '@react-native-picker/picker';


import { DATA } from './data/dummy-data';
import { FinalListContext } from './store/context/finallist-context';

import { useNavigation } from "@react-navigation/native";


import React, { useState, useReducer, useContext } from 'react';
import ShareButton from './shareButton';
import ClearList from './ClearList';


import Ionicons from 'react-native-vector-icons/Ionicons';

import { useEffect } from 'react';

import AsyncStorage from '@react-native-async-storage/async-storage';
import AsyncStorageLoader from './AsyncStorageLoader';
import GlobalLookup from './GlobalLookup';
import ItemLookup from './ItemLookup';
import ItemAdd from './ItemAdd';
import AllProducts from './AllProducts';
import { useFocusEffect } from '@react-navigation/native';
import Octicons from 'react-native-vector-icons/Octicons';
import ItemListScreenItem from './ItemListScreenItem';
import { FavListContext } from './store/context/favList-context';


function ItemListScreen() {

  const favListCtx = React.useContext(FavListContext);
  const selectedItemsCtx = React.useContext(FinalListContext);
 //   const favListCtx = React.useContext(FavListContext);
 const [selectedItemList, setSelectedItemList] = useState(selectedItemsCtx.keys);

 const [searchText, setSearchText] = useState('');

 const [globalSearchText, setGlobalSearchText] = useState('');
 const navigation = useNavigation();
 
 const handleClearSearch = () => {
   // Clear both search inputs
   setSearchText('');
   setGlobalSearchText('');   
   
 };

 useEffect(() => {
  const unsubscribe = navigation.addListener('focus', () => {
    console.log('List pressed '+ selectedItemsCtx.keys.length);
    setSelectedItemList(selectedItemsCtx.keys);
  });

  return unsubscribe; // Cleanup when the component unmounts
}, []);    


 useFocusEffect(
  React.useCallback(() => {
    const unsubscribe = navigation.addListener('tabPress', () => {

      // Invoke handleClearSearch when the "Favorite" tab is pressed
      console.log('List pressed '+ selectedItemsCtx.keys.length);
      handleClearSearch();
     // getDataFromFav();
    });

    return unsubscribe;
  }, [navigation])
);


    
  const getDataFromFav = async () => {
    try {
      const selectedItem2 = favListCtx.idValues;
      console.log('Fav list count '+ selectedItem2.length);
      if((selectedItem2.length <1 )) {
      const existingData = await AsyncStorage.getItem('groceryData');
      
      let data = existingData ? JSON.parse(existingData) : [];
      updateSelectedList(data);  
      } else {
        updateSelectedList(selectedItem2);
      }
    } catch (error) {
      console.error('Error saving data: ', error);
    }
    
  }

  const updateSelectedList = (dataList) => {
 
    dataList.forEach((item) => {
      // Call addFinalList on selectedItemsCtx for each item
      selectedItemsCtx.addFinalList(item);
    });    
    setSelectedItemList(selectedItemsCtx.keys);
  };

    const populateFromData = () => {
      selectedItemsCtx.keys = [];
      console.log('DATA '+ DATA.length);
      updateSelectedList(DATA);
    };
    
    const copyFromExistingData = () => {
      selectedItemsCtx.keys = [];
      getDataFromFav();
    };

    {/** const selectedItemList = DATA.filter(item => selectedItemsCtx.keys.includes(item.key)); */}
    

    

   

      const handleShare = (item) => {

        let message = `Grocery List - ${new Date().toLocaleDateString()}\n\n`;
        
        setSelectedItemList(Object.values(selectedItemsCtx.keys));

        Object.values(selectedItemsCtx.keys).forEach((item, index) => {
          message += `${index + 1} )  ${item.name} - ${item.qty}  - ${item.unit} \n`;
        });

        
        // Share the message using the Share API
        Share.share({
          message,
        });
      
      };


      async function clearAllAsyncStorage() {
        try {
          await AsyncStorage.clear();
          console.log('AsyncStorage cleared.');
        } catch (error) {
          console.error('Error clearing AsyncStorage:', error);
        }
      };

      const handleClear = () => {
        
        selectedItemsCtx.clearFinalList();
        setSelectedItemList([]);
        console.log(' selectedItemsCtx.keys '+ selectedItemsCtx.keys.length);
      clearAllAsyncStorage();
      };

      const handleDeleteItem = (itemToDelete) => {
        
        selectedItemsCtx.deleteFinalList(itemToDelete);
        
       
      };

      function getTodayDate() {
        const today = new Date();
        const dateOptions = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        };
        return today.toLocaleDateString(undefined, dateOptions);
      }
      
      const setShareItemState=(item) => {
        console.log('item'+ item.key + ', item.qty '+ item.qty);
        selectedItemsCtx.updateFinalList(item.key, item);
      }
    
      const handleClearList = () => {
        // Display a confirmation alert
        Alert.alert(
          'Clear List',
          'Are you sure you want to clear the list?',
          [
            {
              text: 'Cancel',
              style: 'cancel',
            },
            {
              text: 'OK',
              onPress: () => {
                // Perform the clear list action here
                handleClear();
              },
            },
          ]
        );
      };
      
   
      
      
    return (
      <ScrollView>

       <View> 
       
          {/* <View style={styles.globalSearchContainer}>
            <GlobalLookup searchText={globalSearchText} onSearchTextChange={setGlobalSearchText} onPress={handleClearSearch}/>  

           
          </View> */}
             
         
       </View>

       
        <View>  
            
          
            {globalSearchText ? (        
        <AllProducts searchText={globalSearchText} source="ItemList" onAddNewItemDone={handleClearSearch}/> // Render AllProducts when globalSearchText is not empty
      ): 
      selectedItemsCtx.keys.length === 0 ? ( // If condition is true
         <View style={styles.container}>
         <View style={styles.centerContent}>          
        *   <Image
           source={require("../assets/empty_cart.png")}
           style={styles.imageStyle}
         />
           <Text style={styles.emptyText}>Your grocery list is currently empty {'\n'}{'\n'}
           To get started, please navigate to the 'Grocery Catalog' tab and begin adding items to your list.</Text>
           
         <View style={styles.buttonContainer}>
          {/* <TouchableOpacity style={styles.button} onPress={() => populateFromData()}>
            <Image source={require("../assets/sample.png")} style={styles.icon} />
            <Text style={styles.buttonText}>Add sample items to List</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.button} onPress={() => copyFromExistingData()}>
            <Image source={require("../assets/favroriteicon.png")} style={styles.icon} />
            <Text style={styles.buttonText}>Add favorite items to List</Text>
          </TouchableOpacity> */}
        </View>
         
         </View>
         </View>
      ) : 
        ( 
          <View>
          <View style={{ flexDirection: 'row',  justifyContent: 'flex-end'    }}>
          {/* <ItemAdd />  */}
              <TouchableOpacity onPress={handleShare}>
               <Octicons name="share" size={27} color="#007AFF" style={styles.iconContainer} />
              </TouchableOpacity>
               <TouchableOpacity onPress={handleClear}>
                <Ionicons name="trash-bin" size={27} color="red" style={styles.iconContainer}/>
               </TouchableOpacity>
          </View> 
            <ItemListScreenItem searchText={searchText} /> 
          </View>
        )
      
      }


          
       


           
            <View style={styles.seperator}/>
        </View>
        </ScrollView>
    );
}

export default ItemListScreen;

function Item({ item, onDeleteItem, setShareItemState }) {
  const navigation = useNavigation();
  // Move setShareItemState inside a function and use it directly
  

  const [itemState, dispatch] = useReducer(itemReducer, item);

  
  
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      updateItemValue('RESET');

    });

    return unsubscribe; // Cleanup when the component unmounts
  }, [navigation]);    



  function itemReducer(state, action) {
    switch (action.type) {
      case 'INCREMENT':
        const updatedQty = state.qty + 1;
        const updatedItem = { ...state, qty: updatedQty };

        // Call setShareItemState with the updated item
        setShareItemState(updatedItem);

        return updatedItem;

      case 'DECREMENT':
        const decreasedQty = state.qty > 0 ? state.qty - 1 : state.qty;
        const decreasedItem = { ...state, qty: decreasedQty };

        // Call setShareItemState with the updated item
        setShareItemState(decreasedItem);

        return decreasedItem;

      case 'RESET':
        // ... (no need to call setShareItemState here)
        return { ...state, qty: parseInt(item.qty) };

      default:
        return state;
    }
  }

      
    
  
    const [selectedFrequency, setSelectedFrequency] = useState(item.selectedFrequency);
  
    const updateItemValue = (type) => {

      // Update the local state with the new state
      dispatch({ type });    
    
    };
  
    const handleFrequencyChange = (value) => {
      // Update the selected frequency for the ite
      setSelectedFrequency(value);
    };
  
    return (
      
      <View style={{flex: 1, flexDirection: 'column'}}>
         
          <View style={{flex: 1, flexDirection: 'row', alignItems: 'center', borderBottomColor: 'black' }}>        
         
                <View style={styles.imageAndTextContainer}>
                  

                  <Image source={typeof item.image === 'string' ? { uri: item.image } : item.image} style={styles.logo} />
               
                  
                  <View style={styles.countCounter}>
                    <Text style={styles.listItem}>{item.name} </Text> 
                    <TouchableOpacity onPress={() => onDeleteItem(item)}>

<Image source={require("../assets/trash.png")} style={styles.tinytinyLogo} ></Image> 
</TouchableOpacity>                  
                  </View>
                </View>
                  <View style={styles.secondColumnContainerParent}>
                        <View>
                          <View style={styles.secondColumnContainer}>
                                <View style={styles.countCounter}>
                                
                                  
                                  <TouchableOpacity onPress={() => {   
                                    
                                    updateItemValue('DECREMENT')                       
                                    
                                  }}>
                                  <Image source={require("../assets/minus.png")} style={styles.tinyLogo}
                                
                                  >
                                    </Image>  
                                  </TouchableOpacity>
                                    
                                  <Text style={styles.qtyStyle}>{itemState.qty}</Text
                                  >                                
  
                                    
                                  <TouchableOpacity onPress={() => {                                            
                                    
                                    updateItemValue('INCREMENT')   
                                    
                                    
                                  }}>
                                  <Image source={require("../assets/plus.png")} style={styles.tinyLogo}
                                
                                  >
                                    </Image>  
                                  </TouchableOpacity>
  
                                </View>
                                {/** 
                                <View>
                                <Text style={styles.qtyUnitStyle}>{itemState.unit}</Text> 
                                </View>
                                */}
                          </View>
                      </View>
              {/*
                      <View style={{flex:1, flexDirection: 'row-reverse'}}>                 
  
                        <View style={styles.container}>
                          <Image source={require("../assets/addItem.png")} style={styles.tinyLogo}></Image>         
                          </View>        
                      </View>
                      */}
                      {/** <FavIcon/>  */}
  
                      
                     
                </View>
  
          
            
          </View>       
         
        </View>
    );
  };
  
  
   
  
  const styles = StyleSheet.create({

container: {
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
},
centerContent: {
  alignItems: 'center',
},
emptyText: {
  fontSize: 18,
  color: 'purple',
  textAlign: 'center'
},globalSearchContainer: {

  padding: 3
},
imageStyle: {
  width: 100, // Adjust the width as needed
  height: 100, // Adjust the height as needed
},
      
      secondColumnContainerParent: {
        flexDirection: 'column',     
        
      },
      container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      },
      buttonContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        
      },
      button: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'gray', // Button background color
        padding: 10, // Button padding
        margin: 10, // Margin between buttons
        borderRadius: 5, // Button border radius
      },
      buttonText: {
        color: 'white', // Button text color
        marginLeft: 10, // Spacing between icon and text
        
      },
      icon: {
        width: 30, // Width of the icon
        height: 30, // Height of the icon
      },
      imageAndTextContainer: {
        marginLeft: 5,
        flex: 1, 
        flexDirection: 'column',
        justifyContent: 'center',
        flexDirection: 'row',
        justifyContent: 'left',
        width: '25%',
        padding: 2,
        
      },
      secondColumnContainer: {
        flex: 1, 
        flexDirection: 'column',
        justifyContent: 'left',
        width: '20%',
        marginLeft: 5
  
      },
      tinyLogo: {
        width: 23,
        height: 23,
      },
      tinytinyLogo: {
        width: 20,
        height: 20,
      },
      logo: {
        width: 68,
        height: 68,
      },
      listItem: {        
          width: 70,
          height: 30 ,        
          fontSize: 11,      
          textAlign: 'center',
  
      },
      countCounter: {
        flexDirection: 'row',      
        
      },
      
  iconContainer: {
    margin: 10, // Add margin for spacing between icons
  },
      qtyStyle: {
       
               width: 30,
               marginLeft: 5 ,  
               marginRight: 5, 
               textAlign: 'center',
               alignContent: 'center'
      },
      qtyUnitStyle: {
        borderWidth: 0,
        width: 80,     
        fontSize: 11,      
        textAlign: 'center'
  },
      seperator: {
        width: '100%',
        height: 1,
        backgroundColor: 'gray',
        marginBottom: 5,
        
      },
      buttonAddToList: {
        
        width: '25%',
        justifyContent: 'center',
        marginRight: 2,
        marginLeft: 5,
        flexDirection: 'row',     
        
        alignItems: 'center'
      },
      buttonRemoveFromList: {
        marginTop: 3,
      },
    
      pickerContainer: {
        flexDirection: 'column',
        alignItems: 'left',      
      },
    });


