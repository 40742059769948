import { Alert, Button, View, Image, StyleSheet, Text } from "react-native";
import { launchCameraAsync, useCameraPermissions, PermissionStatus } from 'expo-image-picker';
import { useState } from "react";

function ImagePicker(props) {

    const [pickedImage, setPickedImage] = useState();

    const [cameraPermissionInformation, requestPermission] = useCameraPermissions();

       


        async function verifyPermissions() {
            if(cameraPermissionInformation.status === PermissionStatus.UNDETERMINED) {
                const permissionResponse = await requestPermission();

                return permissionResponse.granted;
            }
            if(cameraPermissionInformation.status === PermissionStatus.DENIED) {
                Alert.alert(

                    'Insufficient Permissions!', 'You need to grant camera permission to use this app to add picture'
                );
                return false;
            }
            return true;
        }

     async function takeImageHandler() {
        const hasPermission = await verifyPermissions();

        if(!hasPermission) {
            return;
        }

        const image = await launchCameraAsync({
            allowsEditing: false,
            aspect: [16,9],
            quality: 0.2,
        })
        console.log(image);
        setPickedImage(image.uri);
        props.onImageSelect(image.uri);
    }

    let imagePreview = <Text> Add Photo</Text>

    if(pickedImage) {
        imagePreview =  <Image source={{uri: pickedImage}} style={styles.imagePreview} />;
    }
    return(
        <View>
           
            <Button title="Add Photo" onPress={takeImageHandler}></Button>
        </View>
    );
}

export default ImagePicker;

const styles = StyleSheet.create({
    imagePreview: {
        width: 100,
        height: 50,        

    }
})