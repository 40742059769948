import { Image, StyleSheet, View } from "react-native";

function ItemLookupButton() {
return (    
    <View style={styles.container}>
        <Image source={require("../assets/itemSearch.png")} style={styles.tinyLogo}>
        </Image>   
        </View>
);
};

const styles = StyleSheet.create({
    container: {
      
    },
    tinyLogo: {
      width: 40,
      height: 40,
    },
    logo: {
      width: 66,
      height: 58,
    },
  });


export default ItemLookupButton;