import React, { useState, useReducer, useContext, useEffect  } from 'react';
import { Text, View, StyleSheet, FlatList, Image, Button,TouchableOpacity, TextInput, ScrollView } from 'react-native';
import {Picker} from '@react-native-picker/picker';

import { DATA } from './data/dummy-data';
import { FinalListContext } from './store/context/finallist-context';


import AsyncStorage from '@react-native-async-storage/async-storage';
import AsyncStorageLoader from './AsyncStorageLoader';

import { Tooltip, colors  } from 'react-native-elements'; 

import { useNavigation } from "@react-navigation/native";
import { fetchItems } from './util/http';
import { FavListContext } from './store/context/favList-context';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { LocalListContext } from './store/context/localList-contextt';
import axios from 'axios';


function FavListScreenItems({ searchText = '', globalIsFavorite }) {


  const [selectedCategory, setSelectedCategory] = useState('All');
 
  const favListCtx = React.useContext(FavListContext);

   const finalListCtx = useContext(FinalListContext);

   const localListCtx = useContext(LocalListContext);

   const navigation = useNavigation();

  const [selectedItems, setSelectedItems] = useState([]);

  const [existingData, setExistingData] = useState([]);
  

  
  const [filteredItems, setFilteredItems] = useState([]);

  const [showPopup, setShowPopup] = useState(false);

  // First : Make the main ds which has real data
  // Filtered results should be used to show in the FlatList
  // this filteredResults is initialized with existingData and the filtered on top it and show it to user

  useEffect(() => {
    // Code for one-time initialization
    console.log('Component is mounted. This code runs only once.');

    // Call your one-time function here
    getProductDataFromServer();
    refreshList();
 
  }, []); // Empty dependency array for one-time effect

  useEffect(() => {    
    // Load data from AsyncStorage during component initialization 
    console.log(' INIT - 1');
   

   
      // Show all items


      refreshList();
      // setFilteredItems([...existingData]);
      // console.log(' Init - filtered REsults ', filteredItems);
     
  }, [globalIsFavorite]);



   // Function to handle category button clicks
   const handleCategoryClick = (category) => {
    console.log('------------------>>>>>>>>>>>>>>>. <<<<<<<<<<<<<<<<<<<<<  ', category);
    setSelectedCategory(category);

      // To use the function and get the filtered items
  const updatedFilteredItems = refreshListChangCategory(globalIsFavorite, category);
  // You can then use updatedFilteredItems in your component.
  

    //const tempFilteredItemd = ([...existingData]);
    const tempFilteredItemd = ([...updatedFilteredItems]);
    console.log(' tempFilteredItemd ', tempFilteredItemd);
    
    if (category != 'All') {
            // Filter items by category
      const itemsInCategory = tempFilteredItemd.filter(item => item.category === category);
      console.log(' itemsInCategory ', itemsInCategory );
      setFilteredItems(itemsInCategory);
         
    }else {
      setFilteredItems(tempFilteredItemd);
    }
    console.log(' filteredItems ', filteredItems );

  


  };

  const refreshListChangCategory = (globalIsFavorite, cat) => {
    console.log(' refreshListChangCategory  ', globalIsFavorite +  cat);
    if (globalIsFavorite) {
      const favItems = [...favListCtx.idValues];
  
      if (cat != 'All') {
        // Filter items by category
        const itemsInCategory = favItems.filter(item => item.category === cat);
        return itemsInCategory;
      } else {
        return favItems;
      }
    } else if (localListCtx.keys.length > 0) {
      // Filter items from localListCtx.keys that are not already in existingData
      const itemsToAdd = localListCtx.keys.filter(localItem => {
        return !existingData.some(existingItem => existingItem.key === localItem.key);
      });
  
      // Concatenate the filtered items with the existing data
      const mergedProducts = [...itemsToAdd, ...existingData];

      
  
      if (itemsToAdd.length > 0) {

        if (cat != 'All') {
          // Filter items by category
          const itemsInCategory = mergedProducts.filter(item => item.category === cat);
          return itemsInCategory;
        } else {
          return mergedProducts;
        }

        
      }
    } else {

      if (cat != 'All') {
        // Filter items by category
        console.log(' slected Category ', cat);
        const itemsInCategory = existingData.filter(item => item.category === cat);
        console.log(' item returning  ', itemsInCategory);
        return itemsInCategory;
      } else {
        return existingData;
      }
     
    }
  };
  



  const getProductDataFromServer = async () => {
    try {        
      // const finalUrl = 'http://192.168.1.230:8080/product/search?name='+encodedSearchText;
      const finalUrl = 'https://localgroceryshop.com/product/findAll';     
    
      const response = await axios.get(
        finalUrl
      );

      if (response.data) {

        const mappedProducts = response.data.map((item) => ({
            key: item.id,
            name: item.name,
            image: item.imageURL,
            qty: 1,
            visible: 'T',
            selectedFrequency: 'Daily',
            storeName: 'costco',
            unit: 'bag',
            isFavorite: false,
          }));
  
          
        // Concatenate the new products with the existing ones
        const mergedProducts = mergedProducts.concat(mappedProducts);

        setExistingData(mergedProducts);
  

      } else {
        console.log('No products found.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      loadDataFromStorageInit();
    }
  };


  function addToList(item, tempExistingData) {
    const existingIndex = tempExistingData.findIndex((favItem) => favItem.key === item.key);
  
    if (existingIndex !== -1) {
      // Update the item if it already exists in the temporary array
      tempExistingData[existingIndex] = item;
    } else {
      // Add the item if it doesn't exist in the temporary array
      tempExistingData.unshift(item);
    }
  }

  
  

  const loadDataFromStorageInit = async () => {
    try {
      // Load data from AsyncStorage
       // Create a temporary array to store the items from DATA
   // Create a temporary array to store the items from DATA
const tempExistingData = [...existingData]; // Copy the existingData array

// Loop through the DATA array
DATA.forEach((item) => {
  // Check if the item is not already present in tempExistingData
  if (!tempExistingData.some((existingItem) => existingItem.key === item.key)) {
    tempExistingData.push(item); // Add the item to tempExistingData
  }
});


// Update the filteredItems state variable with the data that was loaded from AsyncStorage
console.log('tempExistingData ', existingData);



  setFilteredItems(tempExistingData);
  // Update the existingData state with the items in tempExistingData
 setExistingData(tempExistingData);




       
       // Save the updated existingData to AsyncStorage
       saveDataToStorage(tempExistingData);

       
      updateFromLocalList();
      loadDataFromStorage();
  
     
    } catch (error) {
      console.error('Error loading data: ', error);
    }
  };
  

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      console.log('focus');
      updateFromLocalList();
      
      loadDataFromStorage(); // Refresh data when the screen receives focus
      // updateFromLocalList();

      
      refreshList();

    });

    return unsubscribe; // Cleanup when the component unmounts
  }, [navigation, favListCtx.idValues, existingData, filteredItems, selectedCategory, globalIsFavorite]);


  const refreshList = async () => {

    console.log(' refreshList ', globalIsFavorite);
    console.log(' selectedCategory ', selectedCategory);
    
    
  if (globalIsFavorite){
  
    const favItems = [...favListCtx.idValues];

    if (selectedCategory != 'All') {
      // Filter items by category
    const itemsInCategory = favItems.filter(item => item.category === selectedCategory);
    console.log(' itemsInCategory ', itemsInCategory );
    setFilteredItems(itemsInCategory);
      
    } else {

    console.log(' favItems ', favItems);
    setFilteredItems(favItems);
    }
    //setExistingData(favItems);
  }else if(localListCtx.keys.length >0) {
    
    // Filter items from localListCtx.keys that are not already in existingData
    const itemsToAdd = localListCtx.keys.filter((localItem) => {
      return !existingData.some((existingItem) => existingItem.key === localItem.key);
    });

  
  
    // Concatenate the filtered items with the existing data
    const mergedProducts = [...itemsToAdd, ...existingData];
    if(itemsToAdd.length > 0) {

      console.log(' mergedProducts ', mergedProducts);

      if (selectedCategory != 'All') {
        // Filter items by category
      const itemsInCategory = mergedProducts.filter(item => item.category === selectedCategory);
      console.log(' itemsInCategory ', itemsInCategory );
      setFilteredItems(itemsInCategory);
        
      } else {
  
      console.log(' mergedProducts ', mergedProducts);
      setFilteredItems(mergedProducts);
      }

      //setExistingData(mergedProducts);
    }
   
  } else {
    console.log('filterItems refreshList', existingData);

    if (selectedCategory != 'All') {
      // Filter items by category
    const itemsInCategory = existingData.filter(item => item.category === selectedCategory);
    console.log(' existingData ', itemsInCategory );
    setFilteredItems(itemsInCategory);
      
    } else {

    console.log(' existingData ', existingData);
    setFilteredItems(existingData);
    }

    
  
    //setExistingData(existingData);
  }

  
}

  const updateFromLocalList = async () => {
    setExistingData((prevExistingList) => {
      // Filter items from localListCtx.keys that are not already in prevExistingList
      const itemsToAdd = localListCtx.keys.filter((localItem) => {
        return !prevExistingList.some((existingItem) => existingItem.key === localItem.key);
      });
  
      // Concatenate the filtered items with the existing list
    
      const updatedData = [...itemsToAdd, ...prevExistingList];
      console.log(' Complete List ', updatedData);

      setFilteredItems(updatedData);
    
      return updatedData;
    });
     };
  


  async function clearAllAsyncStorage() {
    try {
      await AsyncStorage.clear();
      } catch (error) {
      console.error('Error clearing AsyncStorage:', error);
    }
  };

  const handleDataLoaded = (data) => {
    // Handle the loaded data as needed
    
    // favListCtx.idValues = data;
  };

  // Create a new array with the latest values

  
  const loadDataFromStorage = async () => {
    try {
     // Add values to existingData if there are values in fav keys and existingData is empty  
      if(favListCtx.idValues.length > 0 && existingData.length < 1) {
        setExistingData(favListCtx.idValues); 
         }

      
      // existinData is still empty so load from cache
      if(existingData.length <1) {
      const data = await AsyncStorage.getItem('groceryData');
      const parsedData = JSON.parse(data);
      if (parsedData) {
        console.log('data length ' + parsedData.length);
      } else {
        console.log('parsedData is null or undefined');
        return;
      }
  
      
      
      let finalCombinedData = parsedData;
  
      // if (selectedItem2.length > 0) {
      //   console.log('more than 0 selected ....');
      //   finalCombinedData = [...parsedData, ...selectedItem2];
      // }
  
      if (finalCombinedData) {
        setExistingData(finalCombinedData);      
        
      }

     
    } else {
   // existinData is NOT empty so go through the cache and update the isFav flag based on the current status
      // const latestArray = existingData.map((existingItem) => {
      //   // Find the corresponding item in the latest array
      //   const latestItem = favListCtx.idValues.find((latestItem) => latestItem.key === existingItem.key);
      
      //   if (latestItem) {
      //     // If a corresponding item is found, update the existing item with the latest values
      //     console.log(' MAtch found');
      //     return { ...existingItem, isFavorite: latestItem.isFavorite };
      //   } else {
      //     console.log(' MAtch not found');
      //     return { ...existingItem, isFavorite: false };
      //   }
    
      // });

      // console.log(' latestArray '+ latestArray.length);
      // Iterate over the favListCtx.idValues array
favListCtx.idValues.forEach((latestItem) => {
  const existingItemIndex = existingData.findIndex((existingItem) => existingItem.key === latestItem.key);

  if (existingItemIndex !== -1) {
    // If a corresponding item is found, update the isFavorite value in existingData
    existingData[existingItemIndex].isFavorite = latestItem.isFavorite;
  }
});

// Now, existingData is updated with modified isFavorite values where matches were found


      // setExistingData(latestArray);      

    }
    } catch (error) {
      console.error('Error loading data: ', error);
    }
  };


  const showPopupForDuration = () => {
    setShowPopup(true);
    setTimeout(() => {
        setShowPopup(false);
    }, 700); // Adjust the duration as needed (3000 milliseconds = 3 seconds)
};

  const handleAddToList = (item) => {
    
    finalListCtx.addFinalList(item); 
    showPopupForDuration();
  };

 

  const updateItemFromChild = (updatedItem) => {
    setExistingData((currentExistingData) =>
      currentExistingData.map((item) =>
        item.key === updatedItem.key ? updatedItem : item
      )
    );  
    // Log the updated existingData here to ensure it's the latest value
    // update cache
    updateItemInAsyncStorage(updatedItem);
  };


  const updateItemInAsyncStorage = async (updatedItem) => {
    try {
      // Retrieve the current data from AsyncStorage
      const data = await AsyncStorage.getItem('groceryData');
      const currentData = data ? JSON.parse(data) : [];
  
      // Find the index of the item you want to update
      const index = currentData.findIndex((item) => item.key === updatedItem.key);
  
      if (index !== -1) {
        // Update the specific item with the updated data
        currentData[index] = updatedItem;
  
        // Save the updated data back to AsyncStorage
        await AsyncStorage.setItem('groceryData', JSON.stringify(currentData));
        console.log('Item updated successfully');
      } else {
        console.log('Item not found in data');
      }
    } catch (error) {
      console.error('Error updating item in AsyncStorage:', error);
    }
  };
  
  


  const handleDeleteItem = (itemToDelete) => {
    // Remove the selected item from the existingData array
    const updatedData = existingData.filter((item) => item.key !== itemToDelete.key);
  
    // Update the existingData state with the filtered data
    setExistingData(updatedData);
    
    clearAllAsyncStorage();
  
    // Save the updated data to AsyncStorage
    saveDataToStorage(updatedData);
  };
  

  const navigateToManageFavorite = (item) => {
    navigation.navigate("Item Details", { product: item, updateItemFromChild: updateItemFromChild });
  };

  
  const saveDataToStorage = async (data) => {
    try {      
      await AsyncStorage.setItem('groceryData', JSON.stringify(data));
    } catch (error) {
      console.error('Error saving data: ', error);
    }
  };



  const setShareItemState = (updatedItem) => {
    setExistingData((currentExistingData) =>
      currentExistingData.map((item) => (item.key === updatedItem.key ? updatedItem : item))
    );
    
    // Update the item in the context
    finalListCtx.updateFinalList(updatedItem.key, updatedItem);
  };
  

  // Combine the DATA static array and existingData array
  
    // Filter the items based on the searchText
    
    // if(searchText.length > 0) {
    //    filteredItems =existingData.filter(item => item.name && item.name.includes(searchText));
    // } else 
   
//console.log('          filteredItems          '+ filteredItems.length);


// if (globalIsFavorite){
//   setFilteredItems(favListCtx.idValues);
// }else if(localListCtx.keys.length >0) {
  
//   // Filter items from localListCtx.keys that are not already in existingData
//   const itemsToAdd = localListCtx.keys.filter((localItem) => {
//     return !existingData.some((existingItem) => existingItem.key === localItem.key);
//   });



//   // Concatenate the filtered items with the existing data
//   const mergedProducts = [...itemsToAdd, ...existingData];
//   if(itemsToAdd.length > 0) {
//     setFilteredItems(mergedProducts);
//   }
 
// } else {
//   console.log('filterItems refreshList', filteredItems);
//   setFilteredItems(existingData);

// }


const [categories, setCategories] = useState(['All']); // Initialize with 'All'

let finalCategories = ['All'];

const extractCategories = (items) => {
 
  if (items) { // Check if items is defined
    items.forEach((item) => {
      if (item.category && !categories.includes(item.category)) {
        categories.push(item.category);
     
        
      }
    });
  }

};
extractCategories(filteredItems);

finalCategories = categories;
  
  return (
    <ScrollView>
       <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        {finalCategories.map((category) => (
          <TouchableOpacity
          key={category}
          onPress={() => handleCategoryClick(category)}
          style={[
            styles.categoryButton,
            {
              backgroundColor: category === selectedCategory ? 'blue' : 'gray',
            },
          ]}
        >
          <Text style={styles.categoryButtonText}>{category}</Text>
        </TouchableOpacity>
        ))}
      </View>
    <View>
      <AsyncStorageLoader onDataLoaded={handleDataLoaded} />
          {/* Popup to show for a few milliseconds */}
          {showPopup && (
                <View style={styles.popup}>
                    <Text style={styles.popupText}>Item added to List!</Text>
                </View>
            )}
      <FlatList
      data={filteredItems}
      renderItem={({ item }) => <Item item={item}  onAddToList={handleAddToList} onDeleteItem={handleDeleteItem} setShareItemState={setShareItemState} navigateToManageFavorite={navigateToManageFavorite}/>}
      keyExtractor={(item) => item.key}    
    />
     
     </View>
     </ScrollView>
  );
}

function Item({ item , onAddToList, onDeleteItem, setShareItemState, navigateToManageFavorite}) {


  function itemReducer(state, action) {
   
    switch (action.type) {
      case 'INCREMENT':
        return { ...state, qty: parseInt(state.qty) + 1 };
      case 'DECREMENT':
        return { ...state, qty: parseInt(state.qty) > 0 ? parseInt(state.qty) - 1 : parseInt(state.qty) };
      case 'UPDATE': // Handle the "UPDATE" action to change the quantity
        return { ...state, qty: action.qty };
      case 'UPDATE_NAME': // New action type to update the name
        return { ...state, name: action.name };
      case 'UNIT': // New action type to update the name
        return { ...state, unit: action.unit };
      case 'IMAGE': // New action type to update the name
        return { ...state, image: action.image };
      case 'FAV': // New action type to update the name
        return { ...state, isFavorite: action.isFavorite };
      case 'CATEGORY': // New action type to update the name
        return { ...state, category: action.category };
      default:
        return state;
    }
  }

  useEffect(() => {
    dispatch({ type: 'UPDATE_NAME', name: item.name });
    dispatch({ type: 'IMAGE', image: item.image });
    dispatch({ type: 'FAV', isFavorite: item.isFavorite });
    dispatch({ type: 'UNIT', unit: item.unit });
    dispatch({ type: 'CATEGORY', category: item.category });
  }, [item]);

  
  const [itemState, dispatch] = useReducer(itemReducer, item);

  const [selectedFrequency, setSelectedFrequency] = useState(item.selectedFrequency);

  const updateItemValue = (type) => {
    dispatch({ type });
  };

  const handleFrequencyChange = (value) => {
    // Update the selected frequency for the item
    setSelectedFrequency(value);
  };

  const handleQuantityChange = (text) => {
    const parsedQty = parseInt(text) || 0; // Parse the input text as an integer or use 0 if it's not a valid number
    dispatch({ type: 'UPDATE', qty: parsedQty }); // Dispatch an action to update the quantity
  };

  
  const isFavorite = item.isFavorite; // Use isFavorite directly from the item
  const favListCtx = React.useContext(FavListContext);


  const toggleFavorite = (item) => {
    // Create a copy of the item with the updated isFavorite property
    const updatedItem = { ...item, isFavorite: !item.isFavorite };
  
    // Use setShareItemState to update the item
    setShareItemState(updatedItem);
  
    // Update the favorite list context
    if (updatedItem.isFavorite) {
      favListCtx.addFavList(updatedItem);
      
    } else {
      favListCtx.deleteFavList(updatedItem);
    }
    item.isFavorite = updatedItem.isFavorite;
  };

  return (
    
    <View style={{flex: 1, flexDirection: 'column'}}>
    <View style={styles.seperator}/>
    <View style={{flex: 1, flexDirection: 'row', alignItems: 'center', borderBottomColor: 'black' }}>        
   
          <View style={styles.imageAndTextContainer}>
                              <View style={{flexDirection: 'row'}}>
                                  <View style={{flexDirection: 'column'}}>
                                        <TouchableOpacity onPress={() => navigateToManageFavorite(itemState)}>
                                            <Image source={typeof item.image === 'string' ? ( item.image.length===0 ? require('../assets/noimage-icon.png') : { uri: item.image }) : ((item.image)? item.image :  require('../assets/noimage-icon.png'))} style={{ ...styles.logo, marginLeft: 10 }} />
                                        </TouchableOpacity>

                                     {/* <TouchableOpacity onPress={() => onDeleteItem(item)}>

                                          <Image source={require("../assets/trash.png")} style={styles.tinytinyLogo} ></Image> 
                                      </TouchableOpacity> */}

                                    </View>   



                                    <View style={{flexDirection: 'row'}}>
                                      <View style={styles.heartStyle}>
                                                  <Ionicons
                                                    name={item.isFavorite ? 'heart' : 'heart-outline'}
                                                    size={25}
                                                    color={item.isFavorite ? 'red' : 'gray'}
                                                    onPress={() => toggleFavorite(item)}
                                                  />
                                                  
                                      </View>
                                      <View style={styles.textContainer}>
                                      
                 
                                
                                          <TouchableOpacity>
                                          <Tooltip 
                    // Use the Tooltip component here to display the full item name
                    popover={<Text>{item.name}</Text>}
                    withOverlay={false}
                    width="auto" 
                    backgroundColor={colors.primary} >
                                                    
                                                    <Text style={{ fontWeight: 'bold', fontSize: 15 }}>{item.name.length > 20 ? item.name.slice(0, 20) + '...' : item.name}</Text>
                                              </Tooltip>
                                              </TouchableOpacity>

                                            

                                      </View>  

                                      
                                </View>
                              </View>   
                                              
                            </View>
              
                        <View>
                  
                          <View style={styles.countCounter}>
                          
                            
                              <TouchableOpacity onPress={() => {   
                                updateItemValue('DECREMENT')
                              }}>
                                <Image source={require("../assets/minus.png")} style={styles.tinyLogo}>
                                  </Image>  
                              </TouchableOpacity>
                                
                              <TextInput
                                style={styles.qtyStyle}
                                value={itemState.qty}
                                onChangeText={handleQuantityChange} // Handle changes to the quantity
                                keyboardType="numeric" // Allow only numeric input
                                maxLength={2}
                              />                              
                             <TouchableOpacity onPress={() => {  
                              updateItemValue('INCREMENT') 
                               }}>
                                <Image source={require("../assets/plus.png")} style={styles.tinyLogo}>
                              </Image>  
                            </TouchableOpacity>
                        
                               

                          </View>
                          <Text style={{marginLeft:25}}>{item.unit}</Text>    
                           {/**
                          <View>
                           
                          <Text style={styles.qtyUnitStyle}>{item.unit}</Text> 
                          </View>
                           */}
                   
              
        {/*
                <View style={{flex:1, flexDirection: 'row-reverse'}}>                 

                  <View style={styles.container}>
                    <Image source={require("../assets/addItem.png")} style={styles.tinyLogo}></Image>         
                    </View>        
                </View>
                */}
                {/** <FavIcon/>  */}

                {/** 
                <View>
                  <View style={styles.imagekerContainer}>
                    <Picker
                      selectedValue={selectedFrequency}
                      style={{ height: 10, width: 150, marginBottom: 13 }}
                      onValueChange={handleFrequencyChange}
                    >
                      <Picker.Item label="Daily" value="Daily" />
                      <Picker.Item label="Weekly" value="Weekly" />
                      <Picker.Item label="Quarterly" value="Quarterly" />
                      <Picker.Item label="Yearly" value="Yearly" />
                    </Picker>
                    
                  </View>
                  </View>  
                  */}
          </View>

          <View style={styles.buttonAddToList}>
            {/* <Button title="Add to List" color={'#87CEFA'}> </Button> 
            <Button title='Add To List' onPress={() => onAddToList(itemState)} style={styles.addToListStyle}> 
                
            </Button>
            */}
              {/* <TouchableOpacity
                onPress={() => onAddToList(itemState)}
                style={styles.customButton}
              >
                <Text style={styles.buttonText}>Add To List</Text>
              </TouchableOpacity> */}

              <TouchableOpacity
            style={styles.marginALl}
            activeOpacity={0.7}
            onPress={() => onAddToList(itemState)}
          >
          
          <Ionicons name="add-circle" size={30} color="green" />
          </TouchableOpacity>

          </View>
      
    </View>       
  
  </View>
      
  );
}


 

const styles = StyleSheet.create({
  textContainer: {
    flex: 1, // Take up available space
    width: '70%', // Adjust the width to your preference
    marginRight: 10, // Add margin for spacing
    marginLeft: 10,
  },

  heartStyle: {

    marginLeft: 10,
  },
    secondColumnContainerParent: {
      flexDirection: 'column',     
      
    },
    addToListStyle: {
      borderCurve: true,
      borderColor: 'black',

    },
    imageAndTextContainer: {
      marginLeft: 5,
      flex: 1, 
      flexDirection: 'column',
      justifyContent: 'center',
      flexDirection: 'row',
      justifyContent: 'left',
      width: '25%',
      padding: 2,
      
    },
    marginALl: {

      marginRight: 5
    },
    listItem: {  
      flex: 1,      
     width: '90%',
     height: 40 ,        
     fontSize: 18,      
     textAlign: 'left',    
     paddingLeft: 10,

 },
    secondColumnContainer: {
      flex: 1, 
      flexDirection: 'column',
      justifyContent: 'left',
      width: '20%',
      marginLeft: 5

    },
    tinyLogo: {
      width: 20,
      height: 20,
    },
    tinytinyLogo: {
      width: 20,
      height: 20,
    },
    logo: {
      width: 68,
      height: 68,
    },
    listItem: {        
        width: 70,
        height: 30 ,        
        fontSize: 11,      
        textAlign: 'center',

    },
    countCounter: {
      flexDirection: 'row',      
      
    },
 
    qtyUnitStyle: {
      borderWidth: 0,
      alignContent: 'center',
      width: 110,     
      fontSize: 13,      
      textAlign: 'center'
},
    seperator: {
      width: '100%',
      height: 1,
      backgroundColor: 'gray',
      marginBottom: 5,
      
    },
    buttonAddToList: {
      
      width: '25%',
      justifyContent: 'center',
      marginRight: 2,
      marginLeft: 5,
      flexDirection: 'row',     
      
      alignItems: 'center',
      borderRadius: 5, 
    },
    buttonRemoveFromList: {
      marginTop: 3,
    },
  
    pickerContainer: {
      flexDirection: 'column',
      alignItems: 'left',      
    },
    customButton: {
      backgroundColor: '#87CEFA', // Background color for the button
      paddingVertical: 10,         // Adjust the button's vertical padding
      paddingHorizontal: 10,       // Adjust the button's horizontal padding
      borderRadius: 5,            // Add rounded corners to the button
    },
    itemContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      borderBottomColor: 'black',
    },
    image: {
      width: 68,
      height: 68,
      marginLeft: 10,
    },
    heartStyle: {
      marginLeft: 10,
    },
    textContainer: {
      flex: 1,
      width: '70%',
      marginRight: 10,
      marginLeft: 10,
    },
    counterContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    counterButton: {
      width: 30,
      height: 30,
    },
    qtyStyle: {
      borderWidth: 0.5,
      width: 25,
      marginLeft: 7,
      marginRight: 7,
      textAlign: 'center',
      alignContent: 'center',
    },
    addButton: {
      width: 30,
      justifyContent: 'center',
      marginRight: 2,
      marginLeft: 5,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 5,
    },
    itemName: {
      fontWeight: 'bold',
      fontSize: 20,
    },
    popup: {
      position: 'absolute',
      top: 10,
      right: 10,
      backgroundColor: 'green',
      padding: 10,
      borderRadius: 5,
      zIndex: 999, // Adjust the zIndex to ensure it's displayed on top
  },
  categoryButton: {
    padding: 10,
    margin: 5,
    borderRadius: 5,
    
  },
  
  categoryButtonText: {
    color: 'white',
    fontWeight: 'bold',
  },

  popupText: {
      color: 'white',
  },
  });

export default FavListScreenItems;


