import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';

function ClearButton({ onPress }) {
  return (
    <View style={styles.textItemLookupContainer}>       
    <TouchableOpacity onPress={onPress}   activeOpacity={0.7}>
      <View style={styles.customButton}>
        <Text style={styles.buttonText}>Clear Search</Text>
      </View>
    </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
    textItemLookupContainer : {        
        height: 35,
        flexDirection: "row",
        padding: 4,    
        },
  buttonContainer: {
    backgroundColor: '#87CEFA', // Set your desired background color
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 5,
  },
  
  customButton: {
    backgroundColor: "#87CEFA",
    paddingHorizontal: 10,
    paddingVertical: 5,
    
  },  
  buttonText: {
    color: "black",
    textAlign: "center",        
    fontWeight: "bold",
  },
});

export default ClearButton;
