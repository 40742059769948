import React, { useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

function AsyncStorageLoader({ onDataLoaded }) {
  const [loadedData, setLoadedData] = useState([]);

  useEffect(() => {
    const loadDataFromStorage = async () => {
      try {
        const data = await AsyncStorage.getItem('groceryData');
        
        if (data) {
          const parsedData = JSON.parse(data);
          setLoadedData(parsedData);
          onDataLoaded(parsedData); // Pass the loaded data to a callback function
        }
      } catch (error) {
        console.error('Error loading data from AsyncStorage: ', error);
      }
    };

    loadDataFromStorage();
    console.log('AsyncStorageLoader: loadDataFromStorage executed');
  }, []);

  return null; // This component doesn't render anything, it's just for loading data
}

export default AsyncStorageLoader;
