import { TextInput, View, StyleSheet, TouchableOpacity } from "react-native";
import Ionicons from 'react-native-vector-icons/Ionicons';

function ItemLookup({ searchText, onSearchTextChange,onPress }) {  
    
    return (
    <View style={styles.textItemLookupContainer}>
        
        <Ionicons name="ios-search" style={{padding: 5, alignContent: 'center'}} size={20} />
        <TextInput 
            style={styles.textItemLookup} 
            placeholder="Find item from the list ... "  
            value={searchText}
            onChangeText={(text) => onSearchTextChange(text)}>
        </TextInput>
        <TouchableOpacity onPress={onPress} >
            <Ionicons name="ios-refresh" size={20} color="blue" />
        </TouchableOpacity>
    </View>
    );
}

const styles = StyleSheet.create({
    textItemLookupContainer : {
        borderWidth: 0.3,        
        margin: 5,        
        height: 30,
        flexDirection: 'row',                
    },
    textItemLookup : {
      padding: 1,
      flex: 1,
    }


});

export default ItemLookup;