
import { Text, View, StyleSheet, FlatList, Image, Button,TouchableOpacity, TextInput, Share, ScrollView, Alert } from 'react-native';
import {Picker} from '@react-native-picker/picker';
import ItemListView from './ItemListView';

import { DATA } from './data/dummy-data';
import { FinalListContext } from './store/context/finallist-context';

import { useNavigation } from "@react-navigation/native";
import { Tooltip, colors  } from 'react-native-elements'; 

import React, { useState, useReducer, useContext } from 'react';
import ShareButton from './shareButton';
import ClearList from './ClearList';


import Ionicons from 'react-native-vector-icons/Ionicons';

import { useEffect } from 'react';

import AsyncStorage from '@react-native-async-storage/async-storage';
import AsyncStorageLoader from './AsyncStorageLoader';
import GlobalLookup from './GlobalLookup';
import ItemLookup from './ItemLookup';
import ItemAdd from './ItemAdd';
import AllProducts from './AllProducts';
import { FavListContext } from './store/context/favList-context';



function ItemListScreenItem({ searchText = '' }) {

  
  
  const [globalSearchText, setGlobalSearchText] = useState('');

  
  const selectedItemsCtx = React.useContext(FinalListContext);
 //   const favListCtx = React.useContext(FavListContext);
//  const [selectedItemList, setSelectedItemList] = useState(selectedItemsCtx.keys);

  const navigation = useNavigation();

  const favListCtx = React.useContext(FavListContext);
  const [showFavorites, setShowFavorites] = useState(false); // Add this state variable



useEffect(() => {
    
  // Load data from AsyncStorage during component initialization
  
  loadDataFromStorageInit();

}, [selectedItemsCtx.keys]);








const loadDataFromStorageInit = async () => {
  
    
    loadDataFromStorage();
    //const favItems = await fetchItems('fav');

    
};

useEffect(() => {
  const unsubscribe = navigation.addListener('focus', () => {
    // setSelectedItemList(selectedItemsCtx.keys);
    loadDataFromStorage(); // Refresh data when the screen receives focus    
  });

  return unsubscribe; // Cleanup when the component unmounts
}, [navigation, selectedItemsCtx.keys]);

// Define a function to clear AsyncStorage for a specific key
const clearAsyncStorageByKey = async (key) => {
  try {
    await AsyncStorage.removeItem(key); // Remove the item associated with the specified key

  } catch (error) {
    console.error(`Error clearing AsyncStorage item with key '${key}':`, error);
  }
};

const handleClearSearch = () => {
  // Clear both search inputs  
  setGlobalSearchText('');
};

async function clearAllAsyncStorage() {
  try {
    await AsyncStorage.clear();
   
  } catch (error) {
    console.error('Error clearing AsyncStorage:', error);
  }
};





const loadDataFromStorage = async () => {
  try {

    if(!(selectedItemsCtx.keys && selectedItemsCtx.keys.length > 0))  {
    const data = await AsyncStorage.getItem('groceryDataList');
    const parsedData = JSON.parse(data);
    if (parsedData) {
      console.log('data length ' + parsedData.length);
    } else {
      console.log('parsedData is null or undefined');
      return;
    }

    const selectedItem2 = favListCtx.idValues;
   
    let finalCombinedData = parsedData;

    // if (selectedItem2.length > 0) {
    //   console.log('more than 0 selected ....');
    //   finalCombinedData = [...parsedData, ...selectedItem2];
    // }

    if (finalCombinedData) {
      selectedItemsCtx.keys = finalCombinedData;
      // setSelectedItemList(selectedItemsCtx.keys);      
      await AsyncStorage.setItem('groceryDataList', JSON.stringify(finalCombinedData));
    }
  }
  } catch (error) {
    console.error('Error loading data: ', error);
  }
};


const updateItemInAsyncStorage = async (updatedItem) => {
  try {
    // Retrieve the current data from AsyncStorage
    const data = await AsyncStorage.getItem('groceryDataList');
    const currentData = data ? JSON.parse(data) : [];

    // Find the index of the item you want to update
    const index = currentData.findIndex((item) => item.key === updatedItem.key);

    if (index !== -1) {
      // Update the specific item with the updated data
      currentData[index] = updatedItem;

      // Save the updated data back to AsyncStorage
      await AsyncStorage.setItem('groceryDataList', JSON.stringify(currentData));
      console.log('Item updated successfully');
    } else {
      console.log('Item not found in data');
    }
  } catch (error) {
    console.error('Error updating item in AsyncStorage:', error);
  }
};





    
  const copyFavData = async () => {
    try {
      
      const existingData = await AsyncStorage.getItem('groceryData');
      
      let data = existingData ? JSON.parse(existingData) : [];


      selectedItemsCtx.keys = data;
      // setSelectedItemList(selectedItemsCtx.keys);  

    } catch (error) {
      console.error('Error saving data: ', error);
    }
    
  }


    const populateFromData = () => {
      
      selectedItemsCtx.keys = DATA;
      // setSelectedItemList(selectedItemsCtx.keys);  
    };
    
    const copyFromExistingData = () => {
      copyFavData();
    };

    {/** const ExistingData = DATA.filter(item => selectedItemsCtx.keys.includes(item.key)); */}
    



      const handleShare = (item) => {

        let message = `Grocery List - ${new Date().toLocaleDateString()}\n\n`;
        
        

        Object.values(selectedItemsCtx.keys).forEach((item, index) => {
          message += `${index + 1} )  ${item.name} - ${item.qty} - ${item.unit} \n`;
        });

        
        // Share the message using the Share API
        Share.share({
          message,
        });
      
      };


      const handleClear = () => {

        
        
        selectedItemsCtx.keys = []
      };

 

      async function clearAllAsyncStorage() {
        try {
          await AsyncStorage.clear();
          console.log('AsyncStorage cleared.');
        } catch (error) {
          console.error('Error clearing AsyncStorage:', error);
        }
      };


      const saveDataToStorage = async (data) => {
        try {      
          await AsyncStorage.setItem('groceryDataList', JSON.stringify(data));
        } catch (error) {
          console.error('Error saving data: ', error);
        }
      };

      const handleDeleteItem = (itemToDelete) => {
        // Remove the selected item from the existingData array
        
        
        selectedItemsCtx.deleteFinalList(itemToDelete);
        
        
        
        
        // setSelectedItemList(selectedItemsCtx.keys);  
        
        console.log( '           selectedItemsCtx.keys   '+ selectedItemsCtx.keys.length);
        
        clearAllAsyncStorage();
    
        saveDataToStorage(updatedData);
      };

      function getTodayDate() {
        const today = new Date();
        const dateOptions = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        };
        return today.toLocaleDateString(undefined, dateOptions);
      }
      
      const setShareItemState=(item) => {
        
        console.log(' setShareItemState item:', item);
console.log('item.isFavorite----->', item.isFavorite);
        selectedItemsCtx.updateFinalList(item.key, item);
                         
        // setSelectedItemList(selectedItemsCtx.keys);
        console.log(' VVVVVVVVVVVVVVVVVVVVVVVVVV ', selectedItemsCtx.keys);
       
      }
    
      const handleClearList = () => {
        // Display a confirmation alert
        Alert.alert(
          'Clear List',
          'Are you sure you want to clear the list?',
          [
            {
              text: 'Cancel',
              style: 'cancel',
            },
            {
              text: 'OK',
              onPress: () => {
                // Perform the clear list action here
                handleClear();
              },
            },
          ]
        );
      };
      
      const navigateToManageFavorite = (item) => {
        console.log('navigateToManageFavorite --', item);        
        navigation.navigate("Item Details", { product: item, updateItemFromChild: updateItemFromChild });
      };
      
  
      const updateItemFromChild = (updatedItem) => {
        console.log('My item ', updatedItem);
        console.log('Itemlist :: selectedItemsCtx.keys before ', selectedItemsCtx.keys);
        const currentExistingData = selectedItemsCtx.keys;
        console.log('Itemlist :: currentExistingData ', currentExistingData);
      
        const updatedData = currentExistingData.map((item) =>
          item.key === updatedItem.key ? updatedItem : item
        );
      
        // Now, update the context with the updatedData
        selectedItemsCtx.keys = updatedData;
      
        // Log the updated existingData here to ensure it's the latest value
        console.log('Itemlist :: selectedItemsCtx.keys after ', selectedItemsCtx.keys);
        updateItemInAsyncStorage(updatedItem);
      };
      


      selectedItemsCtx.keys.sort((a, b) => a.name.localeCompare(b.name));
   
      
      
    return (
      <ScrollView>       
        <View>              

            <View style={styles.listContainer}>
        {globalSearchText ? (
            <AllProducts searchText={globalSearchText} /> // Render AllProducts when globalSearchText is not empty
          ) : (
            <View>
                <FlatList
                data={selectedItemsCtx.keys}
                renderItem={({ item }) => <Item item={item} onDeleteItem={handleDeleteItem} setShareItemState={setShareItemState} navigateToManageFavorite={navigateToManageFavorite}/>}
                keyExtractor={(item) => item.key}/>
            </View>
          )}
       </View>


           
            <View style={styles.seperator}/>
        </View>
        </ScrollView>
    );
}

export default ItemListScreenItem;

function Item({ item, onDeleteItem, setShareItemState, navigateToManageFavorite }) {
  const navigation = useNavigation();
  // Move setShareItemState inside a function and use it directly
  

  const [itemState, dispatch] = useReducer(itemReducer, item);
  

  
  
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      updateItemValue('RESET');

    });

    return unsubscribe; // Cleanup when the component unmounts
  }, [navigation]);    



  function itemReducer(state, action) {
    switch (action.type) {
      case 'INCREMENT':
        const updatedQty = state.qty + 1;
        const updatedItem = { ...state, qty: updatedQty };

        // Call setShareItemState with the updated item
        setShareItemState(updatedItem);

        return updatedItem;

      case 'DECREMENT':
        const decreasedQty = state.qty > 0 ? state.qty - 1 : state.qty;
        const decreasedItem = { ...state, qty: decreasedQty };

        // Call setShareItemState with the updated item
        setShareItemState(decreasedItem);

        return decreasedItem;

      case 'UNIT': // New action type to update the name
        return { ...state, unit: action.unit };

      case 'RESET':
        // ... (no need to call setShareItemState here)
        return { ...state, qty: parseInt(item.qty) };

      case 'UPDATE_NAME': // New action type to update the name
        return { ...state, name: action.name };
      case 'IMAGE': // New action type to update the name
        return { ...state, image: action.image };
      case 'FAV': // New action type to update the name
        return { ...state, isFavorite: action.isFavorite };

      default:
        return state;
    }
  }



  useEffect(() => {
    dispatch({ type: 'UPDATE_NAME', name: item.name });
    dispatch({ type: 'IMAGE', image: item.image });
    dispatch({ type: 'FAV', isFavorite: item.isFavorite });
    dispatch({ type: 'UNIT', unit: item.unit });
  }, [item]);

    
  
    const [selectedFrequency, setSelectedFrequency] = useState(item.selectedFrequency);
  
    const updateItemValue = (type) => {

      // Update the local state with the new state
      dispatch({ type });    
    
    };

    

    const isFavorite = item.isFavorite; // Use isFavorite directly from the item
    const favListCtx = React.useContext(FavListContext);
  

    const toggleFavorite = (item) => {
      // Create a copy of the item with the updated isFavorite property
      const updatedItem = { ...item, isFavorite: !item.isFavorite };
    
      // Use setShareItemState to update the item
      setShareItemState(updatedItem);
    
      // Update the favorite list context
      if (updatedItem.isFavorite) {
        console.log('  addFavList '  );
        favListCtx.addFavList(updatedItem);
      } else {
        console.log('  deleteFavList ' , updatedItem);
        favListCtx.deleteFavList(updatedItem);
      }
      
    };
    

    
  
    const handleFrequencyChange = (value) => {
      // Update the selected frequency for the ite
      setSelectedFrequency(value);
    };


    const handleQuantityChange = (text) => {
      const parsedQty = parseInt(text) || 0; // Parse the input text as an integer or use 0 if it's not a valid number
      dispatch({ type: 'UPDATE', qty: parsedQty }); // Dispatch an action to update the quantity
    };
  
  
    return (
      
      // <View style={{flex: 1, flexDirection: 'column'}}>
         
      //     <View style={{flex: 1, flexDirection: 'row', alignItems: 'center', borderBottomColor: 'black' }}>        
         
         
      //           <View style={styles.imageAndTextContainer}>
      //               <View style={{flexDirection: 'row'}}>
      //                     <View style={{flexDirection: 'column'}}>
      //                           <TouchableOpacity onPress={() => navigateToManageFavorite(item)}>
      //                               <Image source={typeof item.image === 'string' ? ( item.image.length===0 ? require('../assets/noimage-icon.png') : { uri: item.image }) : item.image} style={{ ...styles.logo, marginLeft: 10 }} />
      //                           </TouchableOpacity>
      //                           <View style={{flexDirection: 'row'}}> 
      //                                 <TouchableOpacity onPress={() => onDeleteItem(item)}>

      //                                     <Image source={require("../assets/trash.png")} style={styles.tinytinyLogo} ></Image> 
      //                                 </TouchableOpacity>
      //                                 <View style={{marginLeft:48}}> 
      //                                   <Ionicons
      //                                     name={item.isFavorite ? 'heart' : 'heart-outline'}
      //                                     size={22}
      //                                     color={item.isFavorite ? 'red' : 'gray'}
      //                                     onPress={() => toggleFavorite(item)}
      //                                   />
      //                                 </View>
                                  
      //                           </View>

                              
                
      //                     </View>   
      //                     <View style={styles.textContainer}>
                      
      //                     <TouchableOpacity onPress={() => navigateToManageFavorite(item)}>
      //                               <Text style={{fontWeight: 'bold', fontSize: 20}}>{item.name} </Text>
      //                         </TouchableOpacity>
      //                         </View>  
      //                 </View>   
                                      
      //               </View>

                      
      //             <View style={styles.secondColumnContainerParent}>
      //                   <View>
      //                     <View style={styles.secondColumnContainer}>
      //                           <View style={styles.countCounter}>
                                
                                  
      //                             <TouchableOpacity onPress={() => {   
                                    
      //                               updateItemValue('DECREMENT')                       
                                    
      //                             }}>
      //                             <Image source={require("../assets/minus.png")} style={styles.tinyLogo}
                                
      //                             >
      //                               </Image>  
      //                             </TouchableOpacity>
                                    
      //                             <Text style={styles.qtyStyle}>{itemState.qty}</Text
      //                             >                                
  
                                    
      //                             <TouchableOpacity onPress={() => {                                            
                                    
      //                               updateItemValue('INCREMENT')   
                                    
                                    
      //                             }}>
      //                             <Image source={require("../assets/plus.png")} style={styles.tinyLogo}
                                
      //                             >
      //                               </Image>  
      //                             </TouchableOpacity>
  
      //                           </View>

                                                        
      //                           {/** 
      //                           <View>
      //                           <Text style={styles.qtyUnitStyle}>{itemState.unit}</Text> 
      //                           </View>
      //                           */}
      //                     </View>
      //                 </View>
      //         {/*
      //                 <View style={{flex:1, flexDirection: 'row-reverse'}}>                 
  
      //                   <View style={styles.container}>
      //                     <Image source={require("../assets/addItem.png")} style={styles.tinyLogo}></Image>         
      //                     </View>        
      //                 </View>
      //                 */}
      //                 {/** <FavIcon/>  */}
  
                      
                     
      //           </View>
  
          
            
      //     </View>
      //     <View style={styles.seperator}/>  
         
      //   </View>

      <View style={{flex: 1, flexDirection: 'column'}}>
      <View style={styles.seperator}/>
      <View style={{flex: 1, flexDirection: 'row', alignItems: 'center', borderBottomColor: 'black' }}>        
     
            <View style={styles.imageAndTextContainer}>
                                <View style={{flexDirection: 'row'}}>
                                    <View style={{flexDirection: 'column'}}>
                                          <TouchableOpacity onPress={() => navigateToManageFavorite(item)}>                                              
                                              <Image source={typeof item.image === 'string' ? ( item.image.length===0 ? require('../assets/noimage-icon.png') : { uri: item.image }) : ((item.image)? item.image :  require('../assets/noimage-icon.png'))} style={{ ...styles.logo, marginLeft: 10 }} />
                                          </TouchableOpacity>
  
                                     
  
                                      </View>   
  
  
  <View>
                                      <View style={{flexDirection: 'row'}}>
                                        {/* <View style={styles.heartStyle}>
                                                    <Ionicons
                                                      name={item.isFavorite ? 'heart' : 'heart-outline'}
                                                      size={25}
                                                      color={item.isFavorite ? 'red' : 'gray'}
                                                      onPress={() => toggleFavorite(item)}
                                                    />
                                                    
                                        </View> */}
                                        <View style={styles.textContainer}>
                                  
                                        <TouchableOpacity onPress={() => navigateToManageFavorite(item)}>
                                          <Tooltip 
                    // Use the Tooltip component here to display the full item name
                    popover={<Text>{item.name}</Text>}
                    withOverlay={false}
                    width="auto" 
                    backgroundColor={colors.primary} >
                                                    
                                                    <Text style={{ fontWeight: 'bold', fontSize: 15 }}>{item.name.length > 20 ? item.name.slice(0, 20) + '...' : item.name}</Text>
                                              </Tooltip>
                                              </TouchableOpacity>
                                        </View>  
  
                                        
                                  </View>

                                  <TouchableOpacity onPress={() => onDeleteItem(item)}>
  
  <Image source={require("../assets/trash.png")} style={styles.trashLogo} ></Image> 
</TouchableOpacity>
                                </View>   
                                </View>
                                                
                              </View>
                
                          <View>
                    
                            <View style={styles.countCounter}>
                            
                              
                                <TouchableOpacity onPress={() => {   
                                  updateItemValue('DECREMENT')
                                }}>
                                  <Image source={require("../assets/minus.png")} style={styles.tinyLogo}>
                                    </Image>  
                                </TouchableOpacity>
                                  
                                <TextInput
                                  style={styles.qtyStyle}
                                  value={itemState.qty}
                                  onChangeText={handleQuantityChange} // Handle changes to the quantity
                                  keyboardType="numeric" // Allow only numeric input
                                  maxLength={3}
                                />                              
                               <TouchableOpacity onPress={() => {  
                                updateItemValue('INCREMENT') 
                                 }}>
                                  <Image source={require("../assets/plus.png")} style={styles.tinyLogo}>
                                </Image>  
                              </TouchableOpacity>
                           
  
                            </View>
                            <Text style={{marginLeft:25}}>{item.unit}</Text>
                             {/**
                            <View>
                             
                            <Text style={styles.qtyUnitStyle}>{item.unit}</Text> 
                            </View>
                             */}
                     
                
          {/*
                  <View style={{flex:1, flexDirection: 'row-reverse'}}>                 
  
                    <View style={styles.container}>
                      <Image source={require("../assets/addItem.png")} style={styles.tinyLogo}></Image>         
                      </View>        
                  </View>
                  */}
                  {/** <FavIcon/>  */}
  
                  {/** 
                  <View>
                    <View style={styles.imagekerContainer}>
                      <Picker
                        selectedValue={selectedFrequency}
                        style={{ height: 10, width: 150, marginBottom: 13 }}
                        onValueChange={handleFrequencyChange}
                      >
                        <Picker.Item label="Daily" value="Daily" />
                        <Picker.Item label="Weekly" value="Weekly" />
                        <Picker.Item label="Quarterly" value="Quarterly" />
                        <Picker.Item label="Yearly" value="Yearly" />
                      </Picker>
                      
                    </View>
                    </View>  
                    */}
            </View>
  
            {/* <View style={styles.buttonAddToList}>
              {/* <Button title="Add to List" color={'#87CEFA'}> </Button> 
              <Button title='Add To List' onPress={() => onAddToList(itemState)} style={styles.addToListStyle}> 
                  
              </Button>
          
            <TouchableOpacity
                  onPress={() => onAddToList(itemState)}
                  style={styles.customButton}
                >
                  <Text style={styles.buttonText}>Add To List</Text>
                </TouchableOpacity> 
            </View> */}
        
      </View>       
    
    </View>
    );
  };
  
  
   
  
  const styles = StyleSheet.create({
textContainer: {
  flex: 1, // Take up available space
  width: '70%', // Adjust the width to your preference
  marginRight: 10, // Add margin for spacing
  marginLeft: 10,
},

container: {
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
},
centerContent: {
  alignItems: 'center',
},
emptyText: {
  fontSize: 28,
  color: 'purple',
  textAlign: 'center'
},globalSearchContainer: {

  padding: 3
},
heartStyle: {
  marginLeft: 10,
},
      
      secondColumnContainerParent: {
        flexDirection: 'column',     
        
      },
      imageAndTextContainer: {
        marginLeft: 10,
        flex: 1, 
        flexDirection: 'row',
        justifyContent: 'left',
        width: '25%',
        marginLeft: 10,
        padding: 2,
        
      },
      secondColumnContainer: {
        flex: 1, 
        flexDirection: 'column',
        justifyContent: 'left',
        width: '20%',
        marginLeft: 5
  
      },
      tinyLogo: {
        width: 20,
        height: 20,
      },
      tinytinyLogo: {
        width: 20,
        height: 20,
      },
      trashLogo: {
        width: 20,
        height: 20,
        marginLeft: 10,
        marginTop: 15,
      },
      listItem: {  
         flex: 1,      
        width: '90%',
        height: 40 ,        
        fontSize: 18,      
        textAlign: 'left',    
        paddingLeft: 10,

    },
      logo: {
        width: 68,
        height: 68,
      },
      listItem: {    
        width: '90%', 
          height: 40 ,        
          fontSize: 18,      
          textAlign: 'left',
          paddingLeft: 10,
  
      },
      countCounter: {
        flexDirection: 'row', 
        marginRight: 10 
        
      },

      marginALl: {

        marginRight: 30
      },
      
  iconContainer: {
    margin: 10, // Add margin for spacing between icons
    marginRight: 10
  },
      qtyStyle: {
               borderWidth: 0.5,
               width: 25,
               marginLeft: 7 ,  
               marginRight: 7, 
               textAlign: 'center',
               alignContent: 'center'
      },
      qtyUnitStyle: {
        borderWidth: 0,
        width: 80,     
        fontSize: 11,      
        textAlign: 'center'
  },
      seperator: {
        width: '100%',
        height: 1,
        backgroundColor: 'gray',
        marginBottom: 5,
        
      },
      buttonAddToList: {
        
        width: '25%',
        justifyContent: 'center',
        marginRight: 2,
        marginLeft: 5,
        flexDirection: 'row',     
        
        alignItems: 'center'
      },
      buttonRemoveFromList: {
        marginTop: 3,
      },
    
      pickerContainer: {
        flexDirection: 'column',
        alignItems: 'left',      
      },
      favoriteIconContainer: {
        position: 'absolute',
        top: 5, // Adjust as needed to position the icon
        right: 5, // Adjust as needed to position the icon
        zIndex: 1, // Ensures it's above the image
      },
    });


