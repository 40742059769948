import { TextInput, View, StyleSheet, Button, TouchableOpacity, Text } from "react-native";
import Ionicons from 'react-native-vector-icons/Ionicons';
import { useNavigation } from "@react-navigation/native";
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { useState, useEffect } from 'react';

function ItemAdd({ onToggleFavorite, favState }) {
    const navigation = useNavigation();

    const [isFavorite, setIsFavorite] = useState(favState); // Initialize isFavorite with a state variable

    useEffect(() => {
      // Update the isFavorite state when the favState prop changes
      
      setIsFavorite(favState);
     
  }, [favState]);

    const createNewItem = () => {
        
       
    }



    const emptyItem = {
      key: '' + Math.random().toString(), // Use a unique key for the empty item
      name: '',
      image: '',
      qty: 1, // You can set the default quantity as needed
      visible: 'T',
      selectedFrequency: '',
      storeName: '',
      unit: '',
      isFavorite: false,
    };

 


    const toggleFavorite = () => {
      console.log(' Toggle Global ');
      const updatedIsFavorite = !isFavorite; // Toggle isFavorite
      setIsFavorite(updatedIsFavorite); // Update the isFavorite state
      onToggleFavorite(updatedIsFavorite); // Invoke the onToggleFavorite callback with the updated value
    };

    
    return (
    <View style={styles.iconContainer}
    //style={styles.textItemLookupContainer}

    
    >       

{/* 
       <TouchableOpacity
        style={styles.customspace}
        activeOpacity={0.7}
        onPress={() => navigation.navigate("Item Details", { product: emptyItem })}
        
      >
      
          <Ionicons name="add-circle" size={30} color="green" />
      </TouchableOpacity> */}

      <Ionicons
                      name={isFavorite ? 'heart' : 'heart-outline'}
                      size={30}
                   
                      color={isFavorite ? 'red' : 'gray'}
                      onPress={() => toggleFavorite()}
                    />

    </View>
    );
}

const styles = StyleSheet.create({
    textItemLookupContainer : {        
    height: 35,
    flexDirection: "row",
    padding: 4,    
    },
    textItemLookup : {
      padding: 1
    },
    customspace : {
      marginLeft: 10
    },
    customButton: {
        backgroundColor: "#87CEFA",
        paddingHorizontal: 10,
        paddingVertical: 5,
        elevation: 3, // For a slight shadow on Android
      },  
      buttonText: {
        color: "black",
        textAlign: "center",        
        fontWeight: "bold",
      },

      
      iconContainer: {
        flex: 1,
        
        marginRight: 30, // Add margin for spacing between icons
        alignItems: 'flex-end', 
        flexDirection: 'row-reverse'
      },

});

export default ItemAdd;