import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import About from "./About";
import Contact from "./Contact";
import LandingScreen from "./LandingScreen";
const Stack = createStackNavigator();

const MainStackNavigator = () => {
  return (
    <Stack.Navigator >
      <Stack.Screen name="landing page" component={LandingScreen} />
      
    </Stack.Navigator>
  );
};
const ContactStackNavigator = () => {
  return (
    <Stack.Navigator >
      <Stack.Screen name="Contact" component={Contact} />
    </Stack.Navigator>
  );
};
export { MainStackNavigator, ContactStackNavigator };