import { createContext, useState } from "react";

export const FavListContext = createContext({
    keys: [],
    addFavList: (key) => {},    
});

function FavListContextProvider({children}) {
  const [favListKeys, setFavListKeys] =    useState([]);

  function addFavList(item) {  
    console.log(' addFavList = ', item);
    const existingIndex = favListKeys.findIndex((favItem) => favItem.key === item.key);
    console.log(' existingIndex = ', existingIndex);
    if (existingIndex !== -1) {
      // Update the item if it already exists
      setFavListKeys((currentFavList) => {
        const updatedFavList = [...currentFavList];
        updatedFavList[existingIndex] = item;
        return updatedFavList;
      });
    } else {
      // Add the item if it doesn't exist
      console.log(' Add to list ');
      setFavListKeys((currentFavList) => [item, ...currentFavList]);
    }
    console.log(' favListKeys = '+ favListKeys.length);
  }
  

  function clearFavList() {
    setFavListKeys([]);
  }
  
  function deleteFavList(item) {
   
    setFavListKeys((currentFavList) => currentFavList.filter( (keyId) => keyId.key != item.key));
   
  }


  const value = {
    idValues: favListKeys,    
    addFavList: addFavList,  
    deleteFavList: deleteFavList,
    clearFavList: clearFavList, 
  }

    return <FavListContext.Provider value={value}>{children}</FavListContext.Provider>
}

export default FavListContextProvider;