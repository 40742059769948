import React, { useState, useReducer, useContext, useEffect  } from 'react';
import { Text, View, StyleSheet, FlatList, Image, Button,TouchableOpacity, TextInput, ScrollView } from 'react-native';
import {Picker} from '@react-native-picker/picker';

import { DATA } from './data/dummy-data';
import { FinalListContext } from './store/context/finallist-context';


import AsyncStorage from '@react-native-async-storage/async-storage';
import AsyncStorageLoader from './AsyncStorageLoader';



import { useNavigation } from "@react-navigation/native";
import { fetchItems } from './util/http';
import { FavListContext } from './store/context/favList-context';
import Ionicons from 'react-native-vector-icons/Ionicons';


function ItemListView({ searchText = '' }) {


  const favListCtx = React.useContext(FavListContext);

  

   const finalListCtx = useContext(FinalListContext);
   const navigation = useNavigation();

  const [selectedItems, setSelectedItems] = useState([]);

  const [existingData, setExistingData] = useState([]);
  useEffect(() => {
    console.log('*************************************************************');
    // Load data from AsyncStorage during component initialization
    
    loadDataFromStorageInit();
  
  }, []);




  

  

  const loadDataFromStorageInit = async () => {
    try {
      
      loadDataFromStorage();
      //const favItems = await fetchItems('fav');

      console.log(' existingData '+ existingData.length);
      console.log(' DATA '+ DATA.length);

      
      

      const combinedData = [...existingData,...DATA];
      
      // combinedData.push(selectedItem);
      
      //const combinedData = [...combinedDataPhase2, ...favItems];
      
      console.log('  combinedData '+ combinedData.length+'<');

      // Combine the DATA static array and existingData array
       if (combinedData) {
        setExistingData(combinedData);
        saveDataToStorage(combinedData);
        
        
      } else {
        // If DATA is empty, set existingData to an empty array.
        setExistingData([]);
      }
    
    } catch (error) {
      console.error('Error loading data: ', error);
    }
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
      loadDataFromStorage(); // Refresh data when the screen receives focus
    });

    return unsubscribe; // Cleanup when the component unmounts
  }, [navigation]);
 
  // Define a function to clear AsyncStorage for a specific key
  const clearAsyncStorageByKey = async (key) => {
    try {
      await AsyncStorage.removeItem(key); // Remove the item associated with the specified key
      console.log(`AsyncStorage item with key '${key}' cleared.`);
    } catch (error) {
      console.error(`Error clearing AsyncStorage item with key '${key}':`, error);
    }
  };

  async function clearAllAsyncStorage() {
    try {
      await AsyncStorage.clear();
      console.log('AsyncStorage cleared.');
    } catch (error) {
      console.error('Error clearing AsyncStorage:', error);
    }
  };

  const handleDataLoaded = (data) => {
    // Handle the loaded data as needed
    
    setExistingData(data);
  };

  
  const loadDataFromStorage = async () => {
    try {
      const data = await AsyncStorage.getItem('groceryData');
      const parsedData = JSON.parse(data);
      if (parsedData) {
        console.log('data length ' + parsedData.length);
      } else {
        console.log('parsedData is null or undefined');
        return;
      }
  
      const selectedItem2 = favListCtx.idValues;
      console.log('selectedItem ' + selectedItem2.length);
  
      let finalCombinedData = parsedData;
  
      // if (selectedItem2.length > 0) {
      //   console.log('more than 0 selected ....');
      //   finalCombinedData = [...parsedData, ...selectedItem2];
      // }
  
      console.log('finalCombinedData ' + finalCombinedData.length);
      if (finalCombinedData) {
        setExistingData(finalCombinedData);
        await AsyncStorage.setItem('groceryData', JSON.stringify(finalCombinedData));
      }
    } catch (error) {
      console.error('Error loading data: ', error);
    }
  };



  const handleAddToList = (item) => {
   const existingItemIndex = finalListCtx.finalList.findIndex((listItem) => listItem.key === item.key);
  
    if (existingItemIndex !== -1) {
      // Item with the same key already exists, update its quantity
      finalListCtx.updateFinalList(item.key, item);
    } else {
      // Item doesn't exist in the list, add it
      finalListCtx.addFinalList(item);
      
    }  

  };

  const handleDeleteItem = (itemToDelete) => {
    // Remove the selected item from the existingData array
    
    const updatedData = existingData.filter((item) => item.key !== itemToDelete.key);
    
    
    setExistingData(updatedData);
    clearAllAsyncStorage();

    saveDataToStorage(updatedData);
  };

  const saveDataToStorage = async (data) => {
    try {      
      await AsyncStorage.setItem('groceryData', JSON.stringify(data));
    } catch (error) {
      console.error('Error saving data: ', error);
    }
  };

  const setShareItemState=(item) => {
        
   
    finalListCtx.updateFinalList(item.key, item);

   
  }

  // Combine the DATA static array and existingData array
  
    // Filter the items based on the searchText
    let filteredItems;
  
    if(searchText.length > 0) {
       filteredItems = existingData.filter(item => item.name && item.name.includes(searchText));
    } else {
       filteredItems = existingData;
    }



  
  return (
    <ScrollView>
    <View>
      <AsyncStorageLoader onDataLoaded={handleDataLoaded} />
      <FlatList
      data={filteredItems}
      renderItem={({ item }) => <Item item={item}  onAddToList={handleAddToList} setShareItemState={setShareItemState} onDeleteItem={handleDeleteItem}/>}
      keyExtractor={(item) => item.key}    
    />
     
     </View>
     </ScrollView>
  );
}

function Item({ item , onAddToList, setShareItemState, onDeleteItem}) {

  function itemReducer(state, action) {
    switch (action.type) {
      case 'INCREMENT':
        return { ...state, qty: parseInt(state.qty) + 1 };
      case 'DECREMENT':
        return { ...state, qty: parseInt(state.qty) > 0 ? parseInt(state.qty) - 1 : parseInt(state.qty) };
      case 'UPDATE': // Handle the "UPDATE" action to change the quantity
        return { ...state, qty: action.qty };
      default:
        return state;
    }
  }

  
  const [itemState, dispatch] = useReducer(itemReducer, item);

  const [selectedFrequency, setSelectedFrequency] = useState(item.selectedFrequency);

  const updateItemValue = (type) => {
    dispatch({ type });
  };

  const handleFrequencyChange = (value) => {
    // Update the selected frequency for the item
    setSelectedFrequency(value);
  };

  const handleQuantityChange = (text) => {
    const parsedQty = parseInt(text) || 0; // Parse the input text as an integer or use 0 if it's not a valid number
    dispatch({ type: 'UPDATE', qty: parsedQty }); // Dispatch an action to update the quantity
  };
  
  const isFavorite = item.isFavorite; // Use isFavorite directly from the item
  const favListCtx = React.useContext(FavListContext);

  const toggleFavorite = (item) => {
    console.log(' item.isFavorite '+ item.isFavorite);
    // Create a copy of the item with the updated isFavorite property
    const updatedItem = { ...item, isFavorite: !item.isFavorite };
  
    // Use setShareItemState to update the item
    setShareItemState(updatedItem);
  
    // Update the favorite list context
    if (updatedItem.isFavorite) {
      console.log('  addFavList '  );
      favListCtx.addFavList(updatedItem);
    } else {
      console.log('  deleteFavList ' , updatedItem);
      favListCtx.deleteFavList(updatedItem);
    }
  };
  

  return (
    
    <View style={{flex: 1, flexDirection: 'column'}}>
        <View style={styles.seperator}/>
        <View style={{flex: 1, flexDirection: 'row', alignItems: 'center', borderBottomColor: 'black' }}>        
       
              <View style={styles.imageAndTextContainer}>
                                  <View style={{flexDirection: 'row'}}>
                                      <View style={{flexDirection: 'column'}}>
                                            <TouchableOpacity onPress={() => navigateToManageFavorite(item)}>
                                                <Image source={typeof item.image === 'string' ? ( item.image.length===0 ? require('../assets/noimage-icon.png') : { uri: item.image }) : item.image} style={{ ...styles.logo, marginLeft: 10 }} />
                                            </TouchableOpacity>
                                            
                                        </View>   

                                        <View style={{flexDirection: 'row'}}>
                                          <View style={{marginLeft: 7}}> 
                                                      <Ionicons
                                                        name={item.isFavorite ? 'heart' : 'heart-outline'}
                                                        size={25}
                                                        color={item.isFavorite ? 'red' : 'gray'}
                                                        onPress={() => toggleFavorite(item)}
                                                      />
                                                      
                                          </View>
                                          <View style={styles.textContainer}>
                                    
                                              <TouchableOpacity onPress={() => navigateToManageFavorite(item)}>
                                                        <Text style={{fontWeight: 'bold', fontSize: 20}}>{item.name} </Text>
                                                  </TouchableOpacity>
                                          </View>  

                                          
                                    </View>
                                  </View>   
                                                  
                                </View>
                  
                            <View>
                      
                              <View style={styles.countCounter}>
                              
                                
                                  <TouchableOpacity onPress={() => {   
                                    updateItemValue('DECREMENT')
                                  }}>
                                    <Image source={require("../assets/minus.png")} style={styles.tinyLogo}>
                                      </Image>  
                                  </TouchableOpacity>
                                    
                                  <TextInput
                                    style={styles.qtyStyle}
                                    value={itemState.qty.toString()}
                                    onChangeText={handleQuantityChange} // Handle changes to the quantity
                                    keyboardType="numeric" // Allow only numeric input
                                    maxLength={3}
                                  />                              
                                 <TouchableOpacity onPress={() => {  
                                  updateItemValue('INCREMENT') 
                                   }}>
                                    <Image source={require("../assets/plus.png")} style={styles.tinyLogo}>
                                  </Image>  
                                </TouchableOpacity>

                              </View>
                               {/**
                              <View>
                               
                              <Text style={styles.qtyUnitStyle}>{item.unit}</Text> 
                              </View>
                               */}
                       
                  
            {/*
                    <View style={{flex:1, flexDirection: 'row-reverse'}}>                 

                      <View style={styles.container}>
                        <Image source={require("../assets/addItem.png")} style={styles.tinyLogo}></Image>         
                        </View>        
                    </View>
                    */}
                    {/** <FavIcon/>  */}

                    {/** 
                    <View>
                      <View style={styles.imagekerContainer}>
                        <Picker
                          selectedValue={selectedFrequency}
                          style={{ height: 10, width: 150, marginBottom: 13 }}
                          onValueChange={handleFrequencyChange}
                        >
                          <Picker.Item label="Daily" value="Daily" />
                          <Picker.Item label="Weekly" value="Weekly" />
                          <Picker.Item label="Quarterly" value="Quarterly" />
                          <Picker.Item label="Yearly" value="Yearly" />
                        </Picker>
                        
                      </View>
                      </View>  
                      */}
              </View>

              <View style={styles.buttonAddToList}>
                {/* <Button title="Add to List" color={'#87CEFA'}> </Button> 
                <Button title='Add To List' onPress={() => onAddToList(itemState)} style={styles.addToListStyle}> 
                    
                </Button>
                */}
                  <TouchableOpacity
                    onPress={() => onAddToList(itemState)}
                    style={styles.customButton}
                  >
                    <Text style={styles.buttonText}>Add To List</Text>
                  </TouchableOpacity>
              </View>
          
        </View>       
      
      </View>
      
  );
}


 

const styles = StyleSheet.create({
  textContainer: {
    flex: 1, // Take up available space
    width: '90%', // Adjust the width to your preference
    marginRight: 10, // Add margin for spacing
    marginLeft: 7,
  },
  secondColumnContainerParent: {
    justifyContent: 'flex-end', // Move children to the end (right in a row layout)
  },
  // Remove the 'flex' and 'marginLeft' properties from 'secondColumnContainer'
  secondColumnContainer: {
    flexDirection: 'column',

  },
    addToListStyle: {
      borderCurve: true,
      borderColor: 'black',

    },
    imageAndTextContainer: {
      flex: 1, 
      flexDirection: 'column',

   
      
    },
   
    tinyLogo: {
      width: 23,
      height: 23,
    },
    tinytinyLogo: {
      width: 20,
      height: 20,
    },
    logo: {
      width: 68,
      height: 68,
    },
    listItem: {        
        width: 70,
        height: 30 ,        
        fontSize: 11,      
        textAlign: 'center',

    },
    countCounter: {
      flexDirection: 'row',      
     
      justifyContent: 'flex-end', 
    },
    qtyStyle: {
             borderWidth: 0.5,
             width: 50,
             marginLeft: 10 ,  
             marginRight: 10, 
             textAlign: 'center',
             alignContent: 'center'
    },
    qtyUnitStyle: {
      borderWidth: 0,
      alignContent: 'center',
      width: 110,     
      fontSize: 13,      
      textAlign: 'center'
},
    seperator: {
      width: '100%',
      height: 1,
      backgroundColor: 'gray',
      marginBottom: 5,
      
    },
    buttonAddToList: {
      
      flex: 1,
     
      marginRight: 10,
      marginLeft: 5,
      flexDirection: 'row',       
      justifyContent: 'flex-end', // Move the "Add To List" button to the right
  
  
    },
    buttonRemoveFromList: {
      marginTop: 3,
    },
  
    pickerContainer: {
      flexDirection: 'column',
      alignItems: 'left',      
    },
    customButton: {
      backgroundColor: '#87CEFA', // Background color for the button
      paddingVertical: 10,         // Adjust the button's vertical padding
      paddingHorizontal: 10,       // Adjust the button's horizontal padding
      borderRadius: 5,            // Add rounded corners to the button
    },
  });

export default ItemListView;


