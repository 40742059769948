import { createContext,  useState, useReducer, useContext, useEffect  } from "react";


export const FinalListContext = createContext({
    keys: [],
    addFinalList: (key) => {},
    deleteFinalList: (key) => {}
});

function FinalListContextProvider({children}) {
  const [finalListKeys, setFinalListKeys] =    useState([]);

  function addFinalList(item) {
    console.log('-------------');
    console.log(item.name);
  
    const existingIndex = finalListKeys.findIndex((finalItem) => finalItem.key === item.key);
  
    if (existingIndex !== -1) {
      // Update the item if it already exists
      setFinalListKeys((currentFinalList) => {
        const updatedFinalList = [...currentFinalList];
        updatedFinalList[existingIndex] = item;
        return updatedFinalList;
      });
    } else {
      // Add the item if it doesn't exist
      setFinalListKeys((currentFinalList) => [item, ...currentFinalList]);
    }
  }
  
  
  

  function deleteFinalList(item) {
    setFinalListKeys((currentFinalList) => currentFinalList.filter( (keyId) => keyId.key != item.key));
  }

  function clearFinalList() {
    setFinalListKeys([]);
  }


  function updateFinalList(key, updatedItem) {
    console.log(' finalListKeys '+ finalListKeys.length);
    console.log('Updating item with key:', key, 'to', updatedItem);
    setFinalListKeys((currentFinalList) =>
      currentFinalList.map((item) => (item.key === key ? updatedItem : item))
    );
    console.log(' *** finalListKeys *** ', finalListKeys);
  }

  const value = {
    keys: finalListKeys,
    finalList: finalListKeys,
    addFinalList: addFinalList,
    deleteFinalList: deleteFinalList,
    updateFinalList: updateFinalList,
    clearFinalList: clearFinalList
  }

  useEffect(() => {
    console.log('Updated finalListKeys:', finalListKeys);
  }, [finalListKeys]);

    return <FinalListContext.Provider value={value}>{children}</FinalListContext.Provider>
}

export default FinalListContextProvider;