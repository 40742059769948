import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createDrawerNavigator } from '@react-navigation/drawer';
import LandingScreen from './components/LandingScreen';


import { Ionicons } from '@expo/vector-icons';

import { MainStackNavigator } from './components/MainStackNavigator';
import About from './components/About';
import Contact from './components/Contact';
import BottomTabNavigator from './components/BottomTabNavigator';
import ManageFavorite from './components/ManageFavorite';
import FavScreen from './components/FavScreen';
import FinalListScreen from './components/FinalListScreen';
import FinalListContextProvider from './components/store/context/finallist-context';
import ItemListView from './components/ItemListView';
import FavListContextProvider from './components/store/context/favList-context';
import AllProducts from './components/AllProducts';
import ItemListScreen from './components/ItemListScreen';
import ItemListScreenItem from './components/ItemListScreenItem';
import LocalListContextProvider from './components/store/context/localList-contextt';
import AppHeader from './components/AppHeader';
import FavListScreenItems from './components/FavListScreenItems';
import FavListScreen from './components/FavListScreen';


const Stack = createNativeStackNavigator();
const Drawer = createDrawerNavigator();



export default function App() {



  return (
    
      
<>
    <StatusBar style='auto'></StatusBar>
     <LocalListContextProvider>
    <FinalListContextProvider>
    <FavListContextProvider>
     

    <NavigationContainer>
         
       <Stack.Navigator>
         {/** <Stack.Screen name="PhoneAuth" component={PhoneAuthScreen} />*/}
          <Stack.Screen name="Grocery List" 
          options={{
            headerBackground: (props) => <AppHeader />, // Use your custom header
          
          }}
          component={BottomTabNavigator}   />       
        
          <Stack.Screen name="Final List" component={FinalListScreen}   />  
           
          <Stack.Screen name="FavListScreen" component={FavListScreen}   />  
          <Stack.Screen name="FavListScreenItems" component={FavListScreenItems}   />      
          <Stack.Screen name="Item Details" component={ManageFavorite}   />                 
          <Stack.Screen name="FavScreen" component={FavScreen}   /> 
          <Stack.Screen name="ItemList" component={ItemListView}   />    
          <Stack.Screen name="AllProducts" component={AllProducts}   />    
          <Stack.Screen name="ItemListScreen" component={ItemListScreen}   />    
          <Stack.Screen name="ItemListScreenItem" component={ItemListScreenItem}   />   
          <Stack.Screen name="ManageFavorite" component={ManageFavorite}   />   
          
          
        </Stack.Navigator> 
        
        {/*
        <Drawer.Navigator screenOptions={{
          drawerContentStyle: {backgroundColor: '#e9f5f8' },
          
        }}>
          <Drawer.Screen name="Grocery Shopping List" component={BottomTabNavigator} style={styles.headerContainer}/> 
          <Drawer.Screen name="About" component={About}  /> 
          <Drawer.Screen name="Contact" component={Contact}  /> 
          <Drawer.Screen name="ManageFavorite" component={ManageFavorite}  /> 
        </Drawer.Navigator>

         */}
        
    </NavigationContainer>
   
    </FavListContextProvider>
    </FinalListContextProvider>
    </LocalListContextProvider>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#fff',
    // alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 35
  },
  searchContainer: {
    flex: 0.4,
    flexDirection: 'row'
  },
  listContainer: {
    flex: 4,
    borderWidth: 0.5,
    
  },
  navigationConatainer: {
    flex: 0.6,
    borderWidth: 0.2,
    borderColor: 'blue'
  },

  headerContainer: {
    fontWeight: 'normal',
    fontFamily:'OpenSans-Regular',
    fontSize: 3
    
 }

});
