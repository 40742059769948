import { createContext,  useState, useReducer, useContext, useEffect  } from "react";


export const LocalListContext = createContext({
    keys: [],
    addLocalList: (key) => {},
    deleteLocalList: (key) => {}
});

function LocalListContextProvider({children}) {
  const [localListKeys, setLocalListKeys] =    useState([]);

  function addLocalList(item) {
    console.log('-------------');
    console.log(item.name);
  
    const existingIndex = localListKeys.findIndex((finalItem) => finalItem.key === item.key);
  
    if (existingIndex !== -1) {
      // Update the item if it already exists
      setLocalListKeys((currentLocalList) => {
        const updatedLocalList = [...currentLocalList];
        updatedLocalList[existingIndex] = item;
        return updatedLocalList;
      });
    } else {
      // Add the item if it doesn't exist
      setLocalListKeys((currentLocalList) => [item, ...currentLocalList]);
    }
  }
  
  
  

  function deleteLocalList(item) {
    setLocalListKeys((currentLocalList) => currentLocalList.filter( (keyId) => keyId.key != item.key));
  }

  function clearLocalList() {
    setLocalListKeys([]);
  }


  function updateLocalList(key, updatedItem) {
    console.log(' LocalListKeys '+ localListKeys.length);
    console.log('Updating item with key:', key, 'to', updatedItem);
    setLocalListKeys((currentLocalList) =>
      currentLocalList.map((item) => (item.key === key ? updatedItem : item))
    );
    console.log(' *** LocalListKeys *** ', localListKeys);
  }

  const value = {
    keys: localListKeys,
    localList: localListKeys,
    addLocalList: addLocalList,
    deleteLocalList: deleteLocalList,
    updateLocalList: updateLocalList,
    clearLocalList: clearLocalList
  }

  useEffect(() => {
    console.log('Updated LocalListKeys:', localListKeys);
  }, [localListKeys]);

    return <LocalListContext.Provider value={value}>{children}</LocalListContext.Provider>
}

export default LocalListContextProvider;