
import { Text, View, StyleSheet, FlatList, Image, Button,TouchableOpacity, Share, ScrollView, Alert } from 'react-native';
import {Picker} from '@react-native-picker/picker';
import ItemListView from './ItemListView';

import { DATA } from './data/dummy-data';
import { FinalListContext } from './store/context/finallist-context';

import { useNavigation } from "@react-navigation/native";


import React, { useState, useReducer, useContext } from 'react';
import ShareButton from './shareButton';
import ClearList from './ClearList';


import Ionicons from 'react-native-vector-icons/Ionicons';

import { useEffect } from 'react';

import AsyncStorage from '@react-native-async-storage/async-storage';
import AsyncStorageLoader from './AsyncStorageLoader';
import GlobalLookup from './GlobalLookup';
import ItemLookup from './ItemLookup';
import ItemAdd from './ItemAdd';
import AllProducts from './AllProducts';
   

function FinalListScreen() {

  const selectedItemsCtx = React.useContext(FinalListContext);
 //   const favListCtx = React.useContext(FavListContext);
 const [selectedItemList, setSelectedItemList] = useState(selectedItemsCtx.keys);

 const [globalSearchText, setGlobalSearchText] = useState('');
 const handleClearSearch = () => {
  // Clear both search inputs
 
  setGlobalSearchText('');
};



     const navigation = useNavigation();
     useEffect(() => {
      const unsubscribe = navigation.addListener('focus', () => {
        
        setSelectedItemList(selectedItemsCtx.keys);
      });
  
      return unsubscribe; // Cleanup when the component unmounts
    }, [navigation]);    

    
  const updateSelectedData = async () => {
    try {
      
      const existingData = await AsyncStorage.getItem('groceryData');
      
      let data = existingData ? JSON.parse(existingData) : [];
      setSelectedItemList(data);  
    } catch (error) {
      console.error('Error saving data: ', error);
    }
    
  }


    const populateFromData = () => {
      setSelectedItemList(DATA);  
    };
    
    const copyFromExistingData = () => {
      updateSelectedData();
    };

    {/** const selectedItemList = DATA.filter(item => selectedItemsCtx.keys.includes(item.key)); */}
    


    if (selectedItemList.length === 0) {
        return (
        <View style={styles.container}>
        <View style={styles.centerContent}>          
       {/**   <Image
          source={require('../assets/empty_cart.png')} // Update with the actual path to your image
          style={styles.image}
        />
          <Text style={styles.emptyText}>Empty List. {'\n'}
          Please go to the Favorite tab and add items to the list.</Text>
          */} 
          <TouchableOpacity
            style={styles.link}
            onPress={() => populateFromData()}
          >
            <Text style={styles.linkText}>Populate from DATA</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.link}
            onPress={() => copyFromExistingData()}
          >
            <Text style={styles.linkText}>Copy from existingData</Text>
          </TouchableOpacity>
        
        </View>
        </View>
        );
      }

      const handleShare = (item) => {

        let message = `Shopping List - ${new Date().toLocaleDateString()}\n\n`;
        
        setSelectedItemList(Object.values(selectedItemsCtx.keys));

        selectedItemList.forEach((item, index) => {
          message += `${index + 1} )  ${item.name} - ${item.qty} \n`;
        });

        
        // Share the message using the Share API
        Share.share({
          message,
        });
      
      };


      const handleClear = () => {

        setSelectedItemList([]);
      
      };

      const handleDeleteItem = (itemToDelete) => {
        
        selectedItemsCtx.deleteFinalList(itemToDelete);
        
       
      };

      function getTodayDate() {
        const today = new Date();
        const dateOptions = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        };
        return today.toLocaleDateString(undefined, dateOptions);
      }
      
      const setShareItemState=(item) => {
        console.log('item'+ item.key + ', item.qty '+ item.qty);
        selectedItemsCtx.updateFinalList(item.key, item);
      }
    
      const handleClearList = () => {
        // Display a confirmation alert
        Alert.alert(
          'Clear List',
          'Are you sure you want to clear the list?',
          [
            {
              text: 'Cancel',
              style: 'cancel',
            },
            {
              text: 'OK',
              onPress: () => {
                // Perform the clear list action here
                handleClear();
              },
            },
          ]
        );
      };
      
   
      
      
    return (
      <ScrollView>

<View> 
          <View>
          <View style={styles.globalSearchContainer}>
            <GlobalLookup searchText={globalSearchText} onSearchTextChange={setGlobalSearchText} onPress={handleClearSearch}/>  
          </View>
          <View>
              <View style={styles.rowContainer}>
              
                <View style={styles.buttonContainer}>
                  <ItemAdd/> 
                </View>
                
              </View>
            </View>         
          </View>
       </View>
       
        <View>  
            <View style={{ flexDirection: 'row',  justifyContent: 'flex-end'    }}>
        
                <TouchableOpacity onPress={handleShare}>
                 <Ionicons name="share-social" size={30} color="#007AFF" style={styles.iconContainer}/>
                </TouchableOpacity>
                <TouchableOpacity onPress={handleClearList}>
    <Ionicons name="trash-bin" size={30} color="red" style={styles.iconContainer}/>
  </TouchableOpacity>
            </View>

            <View style={styles.listContainer}>
        {globalSearchText ? (
            <AllProducts searchText={globalSearchText} /> // Render AllProducts when globalSearchText is not empty
          ) : (
            <View>
                <FlatList
                data={selectedItemList}
                renderItem={({ item }) => <Item item={item} onDeleteItem={handleDeleteItem} setShareItemState={setShareItemState} />}
                keyExtractor={(item) => item.key}/>
            </View>
          )}
       </View>


           
            <View style={styles.seperator}/>
        </View>
        </ScrollView>
    );
}

export default FinalListScreen;

function Item({ item, onDeleteItem, setShareItemState }) {
  const navigation = useNavigation();
  // Move setShareItemState inside a function and use it directly
  

  const [itemState, dispatch] = useReducer(itemReducer, item);

  
  
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      updateItemValue('RESET');

    });

    return unsubscribe; // Cleanup when the component unmounts
  }, [navigation]);    



  function itemReducer(state, action) {
    switch (action.type) {
      case 'INCREMENT':
        const updatedQty = state.qty + 1;
        const updatedItem = { ...state, qty: updatedQty };

        // Call setShareItemState with the updated item
        setShareItemState(updatedItem);

        return updatedItem;

      case 'DECREMENT':
        const decreasedQty = state.qty > 0 ? state.qty - 1 : state.qty;
        const decreasedItem = { ...state, qty: decreasedQty };

        // Call setShareItemState with the updated item
        setShareItemState(decreasedItem);

        return decreasedItem;

      case 'RESET':
        // ... (no need to call setShareItemState here)
        return { ...state, qty: parseInt(item.qty) };

      default:
        return state;
    }
  }

      
    
  
    const [selectedFrequency, setSelectedFrequency] = useState(item.selectedFrequency);
  
    const updateItemValue = (type) => {

      // Update the local state with the new state
      dispatch({ type });    
    
    };
  
    const handleFrequencyChange = (value) => {
      // Update the selected frequency for the ite
      setSelectedFrequency(value);
    };
  
    return (
      
      <View style={{flex: 1, flexDirection: 'column'}}>
         <View style={styles.seperator}/>
          <View style={{flex: 1, flexDirection: 'row', alignItems: 'center', borderBottomColor: 'black' }}>        
         
                <View style={styles.imageAndTextContainer}>
                  

                  <Image source={typeof item.image === 'string' ? { uri: item.image } : item.image} style={styles.logo} />
               
                  
                  <View style={styles.countCounter}>
                    <Text style={styles.listItem}>{item.name} </Text> 
                    <TouchableOpacity onPress={() => onDeleteItem(item)}>

<Image source={require("../assets/trash.png")} style={styles.tinytinyLogo} ></Image> 
</TouchableOpacity>                  
                  </View>
                </View>
                  <View style={styles.secondColumnContainerParent}>
                        <View>
                          <View style={styles.secondColumnContainer}>
                                <View style={styles.countCounter}>
                                
                                  
                                  <TouchableOpacity onPress={() => {   
                                    
                                    updateItemValue('DECREMENT')                       
                                    
                                  }}>
                                  <Image source={require("../assets/minus.png")} style={styles.tinyLogo}
                                
                                  >
                                    </Image>  
                                  </TouchableOpacity>
                                    
                                  <Text style={styles.qtyStyle}>{itemState.qty}</Text
                                  >                                
  
                                    
                                  <TouchableOpacity onPress={() => {                                            
                                    
                                    updateItemValue('INCREMENT')   
                                    
                                    
                                  }}>
                                  <Image source={require("../assets/plus.png")} style={styles.tinyLogo}
                                
                                  >
                                    </Image>  
                                  </TouchableOpacity>
  
                                </View>
                                {/** 
                                <View>
                                <Text style={styles.qtyUnitStyle}>{itemState.unit}</Text> 
                                </View>
                                */}
                          </View>
                      </View>
              {/*
                      <View style={{flex:1, flexDirection: 'row-reverse'}}>                 
  
                        <View style={styles.container}>
                          <Image source={require("../assets/addItem.png")} style={styles.tinyLogo}></Image>         
                          </View>        
                      </View>
                      */}
                      {/** <FavIcon/>  */}
  
                      
                     
                </View>
  
          
            
          </View>       
         
        </View>
    );
  };
  
  
   
  
  const styles = StyleSheet.create({

container: {
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
},
centerContent: {
  alignItems: 'center',
},
emptyText: {
  fontSize: 28,
  color: 'purple',
  textAlign: 'center'
},globalSearchContainer: {

  padding: 3
},
      
      secondColumnContainerParent: {
        flexDirection: 'column',     
        
      },
      imageAndTextContainer: {
        flex: 1, 
        flexDirection: 'column',
        justifyContent: 'center',
        width: '25%',
        
      },
      secondColumnContainer: {
        flex: 1, 
        flexDirection: 'column',
        justifyContent: 'left',
        width: '20%',
        marginLeft: 5
  
      },
      tinyLogo: {
        width: 23,
        height: 23,
      },
      tinytinyLogo: {
        width: 20,
        height: 20,
      },
      logo: {
        width: 68,
        height: 68,
      },
      listItem: {        
          width: 70,
          height: 30 ,        
          fontSize: 11,      
          textAlign: 'center',
  
      },
      countCounter: {
        flexDirection: 'row',      
        
      },
      
  iconContainer: {
    margin: 10, // Add margin for spacing between icons
  },
      qtyStyle: {
               borderWidth: 0.5,
               width: 30,
               marginLeft: 10 ,  
               marginRight: 10, 
               textAlign: 'center',
               alignContent: 'center'
      },
      qtyUnitStyle: {
        borderWidth: 0,
        width: 80,     
        fontSize: 11,      
        textAlign: 'center'
  },
      seperator: {
        width: '100%',
        height: 1,
        backgroundColor: 'gray',
        marginBottom: 5,
        
      },
      buttonAddToList: {
        
        width: '25%',
        justifyContent: 'center',
        marginRight: 2,
        marginLeft: 5,
        flexDirection: 'row',     
        
        alignItems: 'center'
      },
      buttonRemoveFromList: {
        marginTop: 3,
      },
    
      pickerContainer: {
        flexDirection: 'column',
        alignItems: 'left',      
      },
    });


