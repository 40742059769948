export const DATA = [
    // {key: 'PKRX12', image:require("../../assets/bread.png"), name: 'Bread', storeName: 'Costco', qty: '1', unit: 'bag', selectedFrequency: 'Daily', isFavorite: false },    
    // {key: 'PKRX2', image:require("../../assets/eggs.png"), name: 'Eggs', storeName: 'Costco', qty: '1', unit: 'count' , selectedFrequency: 'Daily', isFavorite: false }, 
     {key: 'PKRX3', image:require("../../assets/milk.png"), name: 'Milk', description: 'Fat free', storeName: 'Costco', qty: '1', unit: 'litre', selectedFrequency: '1', isFavorite: false, category: 'Dairy' }, 
    // {key: 'PKRX4', image:require("../../assets/cauliflower.png"), name: 'Cauliflower', storeName: 'Patel Brothers', qty: '1', unit: 'count' , selectedFrequency: 'Daily', isFavorite: false }, 
    // {key: 'PKRX5', image:require("../../assets/onions.png"), name: 'Onions', storeName: 'Patel Brothers', qty: '1', unit: 'pound' , selectedFrequency: 'Daily', isFavorite: false }, 
     {key: 'PKRX6', image:require("../../assets/tomatoes.png"), description: 'Round red tomatoes', name: 'Tomatoes', storeName: 'Patel Brothers', qty: '1', unit: 'kg' , selectedFrequency: '7', isFavorite: false, category: 'Vegetables' }, 
    // {key: 'PKRX7', image:require("../../assets/ginger.png"), name: 'Ginger', storeName: 'Patel Brothers', qty: '1', unit: 'pieces' , selectedFrequency: 'Daily', isFavorite: false }, 
    // {key: 'PKRX8', image:require("../../assets/redchilli.png"), name: 'Red chilli', storeName: 'Patel Brothers', qty: '1', unit: 'count', selectedFrequency: 'Daily', isFavorite: false }, 
    // {key: 'PKRX9', image:require("../../assets/greenchilli.png"), name: 'Green Chilli', storeName: 'Patel Brothers', qty: '1', unit: 'count', selectedFrequency: 'Daily', isFavorite: false }, 
    // {key: 'PKRX11', image:require("../../assets/bananas.png"), name: 'Banana', storeName: 'Costco', qty: '1', unit: 'count' , selectedFrequency: 'Daily', isFavorite: false }, 
    
    // {key: 'PKRX13', image:require("../../assets/garlic.png"), name: 'Garlic', storeName: 'Patel Brothers', qty: '1', unit: 'packet' , selectedFrequency: 'Daily', isFavorite: false }, 
    // {key: 'PKRX14', image:require("../../assets/spinach.png"), name: 'Spinach', storeName: 'Patel Brothers', qty: '1', unit: 'packet' , selectedFrequency: 'Daily', isFavorite: false }, 
    // {key: 'PKRX16', image:require("../../assets/lemon.png"), name: 'Lemon', storeName: 'Patel Brothers', qty: '1', unit: 'packet' , selectedFrequency: 'Daily', isFavorite: false }, 
    // {key: 'PKRX17', image:require("../../assets/cabbage.png"), name: 'Cabbage', storeName: 'Patel Brothers', qty: '1', unit: 'packet' , selectedFrequency: 'Daily', isFavorite: false }, 
    // {key: 'PKRX18', image:require("../../assets/red-pepper.png"), name: 'Red pepper', storeName: 'Patel Brothers', qty: '1', unit: 'packet' , selectedFrequency: 'Daily', isFavorite: false }, 
    // {key: 'PKRX19', image:require("../../assets/potato.png"), name: 'Potato', storeName: 'Patel Brothers', qty: '1', unit: 'packet' , selectedFrequency: 'Daily', isFavorite: false }, 
    // {key: 'PKRX20', image:require("../../assets/eggplant.png"), name: 'Eggplant', storeName: 'Patel Brothers', qty: '1', unit: 'packet' , selectedFrequency: 'Daily', isFavorite: false }, 
    // {key: 'PKRX21', image:require("../../assets/grapes.png"), name: 'Grapes', storeName: 'Patel Brothers', qty: '1', unit: 'packet' , selectedFrequency: 'Daily', isFavorite: false }, 
     {key: 'PKRX22', image:require("../../assets/apples.png"), description: 'Golden Apples', name: 'Apples', storeName: 'Patel Brothers', qty: '1', unit: 'count' , selectedFrequency: '30', isFavorite: false, category: 'Fruits' }, 
    // {key: 'PKRX23', image:require("../../assets/papaya.png"), name: 'Papaya', storeName: 'Patel Brothers', qty: '1', unit: 'count' , selectedFrequency: 'Daily', isFavorite: false }, 
    
  ];
  