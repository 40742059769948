
import React from 'react';
import { Text, View, Image, StyleSheet } from 'react-native';
import { ImageBackground } from 'react-native';



function AppHeader() {
  return (
    
    <ImageBackground
      source={require('../assets/grocerylistheaderbg.png')} // Replace with the actual path to your image
      style={styles.headerBackground}
     
    >
   
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
    headerBackground: {
      flex: 1, // To make the background cover the entire header area
      resizeMode: 'cover', // To cover the entire area while maintaining the aspect ratio
      justifyContent: 'center', // To center the content within the header area
      // You can also add more styles like padding or additional styling for the content
    },
  });
  

export default AppHeader;
