import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View , TouchableOpacity} from 'react-native';

import ItemLookupButton from './ItemLookupButton';
import ItemListView from './ItemListView';
import ItemLookup from './ItemLookup';
import ItemAdd from './ItemAdd';
import React, { useState } from 'react';
import openfood from '../integration/openfood';
import Openfood from '../integration/openfood';
import GlobalLookup from './GlobalLookup';
import AllProducts from './AllProducts';
import ClearButton from './ClearButton';

import { useEffect } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import FavListScreenItems from './FavListScreenItems';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { FavListContext } from './store/context/favList-context';

function FavListScreen({ navigation }) {
  const [searchText, setSearchText] = useState('');

  const [globalSearchText, setGlobalSearchText] = useState('');
  
  const favListCtx = React.useContext(FavListContext);

  const [isFavorite, setIsFavorite] = useState(false);

  const handleClearSearch = () => {
    // Clear both search inputs
    
    setSearchText('');
    setGlobalSearchText('');
    setIsFavorite(false); // Update isFavorite to false 
  };

  

  const handleToggleFavorite = (updatedIsFavorite) => {
    // This function will be invoked from ItemAdd
    // You can use the isFavorite parameter and perform actions as needed
    console.log(' updatedIsFavorite ', updatedIsFavorite);
    setIsFavorite(updatedIsFavorite);  
  };

  const handleClear = () => {
    // Clear both search inputs
    favListCtx.clearFavList();    
  };


  useFocusEffect(
    React.useCallback(() => {
      const unsubscribe = navigation.addListener('tabPress', () => {
        // Invoke handleClearSearch when the "Favorite" tab is pressed
        handleClearSearch();
        // getProductDataFromServer();
      });

      return unsubscribe;
    }, [navigation])
  );

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // setSelectedItemList(selectedItemsCtx.keys);
    
      handleClearSearch(); 
    });
  
    return unsubscribe; // Cleanup when the component unmounts
  }, [navigation]);
  

    return (
        <View style={styles.container}>
      
        {/* search view 
        Tab view
        List view       
   Navigation view */}
   
       <View> 
          <View>
          <View style={styles.globalSearchContainer}>
            <GlobalLookup searchText={globalSearchText} onSearchTextChange={setGlobalSearchText} onPress={handleClearSearch}  onClearSearch={handleClearSearch}/>  
          </View>
          <View>
            

                <View style={styles.rowContainer}>
                {/* <View style={styles.inputContainer}>
                    <ItemLookup searchText={searchText} onSearchTextChange={setSearchText} onPress={handleClearSearch}/>                  
                </View> */}
               
            
            

                    {!globalSearchText && <ItemAdd favState={isFavorite} onToggleFavorite={() => setIsFavorite(!isFavorite)} />}
       



                  {/* <TouchableOpacity onPress={handleClear}>
    <Ionicons name="trash-bin" size={30} color="red" style={styles.iconContainer}/>
  </TouchableOpacity> */}
              
         


                
              </View>
            </View>         
          </View>
       </View>

       <View style={styles.listContainer}>
        {globalSearchText ? (
            <AllProducts searchText={globalSearchText} source="FavList" onAddNewItemDone={handleClearSearch}/> // Render AllProducts when globalSearchText is not empty
          ) : (
            <FavListScreenItems searchText={searchText} globalIsFavorite={isFavorite} /> // Render ItemListView when globalSearchText is empty
          )}
       </View>
       
       
       {/*<Text>Grocery Shopping Management</Text>
       <StatusBar style="auto" />*/}
     </View>
    );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#fff',
    // alignItems: 'center',
    justifyContent: 'space-between',
    padding: 1,
  },
  globalSearchContainer: {

    padding: 3
  },
  rowContainer: {
    flexDirection: 'row'
  },
  searchContainer: {
    width: '100%',
    padding: 2,
    flex: 0.5,
    flexDirection: 'row',
     alignItems: 'center',
    
  },
  listContainer: {
    flex: 4,
    borderWidth: 0.1,
    
  },
  navigationConatainer: {
    flex: 0.6,
    borderWidth: 0.2,
    borderColor: 'blue'
  },
  inputContainer: {
    width: '75%',
    flex: 1,
  },
  buttonContainer: {
    flex:1,
    flexDirection: 'row',
    justifyContent: 'right',
    marginRight: 10,
  },

});

export default FavListScreen;