import React, { useState, useRef  } from "react";
import { Text, View, TextInput, Button, Image, StyleSheet, ScrollView, Switch,TouchableOpacity, Picker  } from "react-native";
import ImagePicker from "./ImagePicker";
import { DATA } from './data/dummy-data';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AsyncStorageLoader from "./AsyncStorageLoader";
import { storeFavItem } from "./util/http";
import { FavListContext } from "./store/context/favList-context";
import { FinalListContext } from "./store/context/finallist-context";

import { useNavigation } from "@react-navigation/native";


import { useRoute } from '@react-navigation/native';
import { LocalListContext } from "./store/context/localList-contextt";
import { Alert} from "react-native";
import { launchCameraAsync, useCameraPermissions, PermissionStatus } from 'expo-image-picker';


const ManageFavorite= ({ route }) => {


  const navigation = useNavigation();
  const { product } = useRoute().params;

  const possibleUnits = ['Pound (lb)','Gallon (gal)', 'Liter (L)', 'Gram (g)', 'Kilogram (kg)', 'Litre', 'count', 'Bag', 'Carton'];

  const possibleCategories = ['Dairy', 'Frozen items', 'Lentils', 'Vegetables', 'Fruit'];


  const handleUpdateItem = (updatedItem) => {
    // Call the function provided by the parent component to update the state
    console.log('ManageFavorite :: handleUpdateItem ', updatedItem);
    // Call the callback function from the parent component
    route.params.updateItemFromChild(updatedItem);
  };


  

  if (!product) {
    return <Text>Loading...</Text>; // or display an error message
  }

  const [itemName, setItemName] = useState(product.name || ""); // Set the initial value to product name
  const [selectedImage, setSelectedImage] = useState(product.image || null); // Set the initial value to product image
  const [isFavorite, setIsFavorite] = useState(product.isFavorite || false); // Set the initial value to product image

  const [itemkey, setItemkey] = useState(product.key || ""); // Set the initial value to product name

  const [itemUnit, setItemUnit] = useState(product.unit || ""); // Set the initial value to product name

  const [itemQty, setItemQty] = useState(product.qty || ""); // Set the initial value to product name
  const [itemDescription, setItemDescription] = useState(product.description || ""); // Set the initial value to product description

  const [itemFrequency, setItemFrequency] = useState(product.selectedFrequency || "0"); // Set the initial value to product name
  const [itemStoreName, setItemStoreName] = useState(product.storeName || ""); // Set the initial value to product name

  const [itemCategory, setItemCategory] = useState(product.category || ""); // Set the initial value to "All"

  const [customCategory, setCustomCategory] = useState(""); // Track custom category

  const selectedItemsCtx = React.useContext(FinalListContext);
  const localListCtx = React.useContext(LocalListContext);

  const [pickedImage, setPickedImage] = useState();

  const customCategoryInputRef = useRef(null); // Ref for custom category input


    const [cameraPermissionInformation, requestPermission] = useCameraPermissions();

       


        async function verifyPermissions() {
            if(cameraPermissionInformation.status === PermissionStatus.UNDETERMINED) {
                const permissionResponse = await requestPermission();

                return permissionResponse.granted;
            }
            if(cameraPermissionInformation.status === PermissionStatus.DENIED) {
                Alert.alert(

                    'Insufficient Permissions!', 'You need to grant camera permission to use this app to add picture'
                );
                return false;
            }
            return true;
        }

     async function takeImageHandler() {
        const hasPermission = await verifyPermissions();

        if(!hasPermission) {
            return;
        }

        const image = await launchCameraAsync({
            allowsEditing: false,
            aspect: [16,9],
            quality: 0.2,
        })
        console.log(image);
        setSelectedImage(image.uri);
        
    }

  const handleItemNameChange = (text) => {
    setItemName(text);
  };

  const toggleFavorite = (flag) => {
    setIsFavorite(flag);
  }

  const handleItemUnitChange = (text) => {
    setItemUnit(text);
  };


  const handleItemDescriptionChange = (text) => {
    setItemDescription(text);
  };
  

  const handleItemQtyChange = (text) => {
    setItemQty(text);
  };


  const handleItemFrequencyChange = (text) => {
    setItemFrequency(text);
  };
  
  const handleItemStoreNameChange = (text) => {
    setItemStoreName(text);
  };
  

  const handleImageSelect = (image) => {
    takeImageHandler();
    setSelectedImage(image);
  };

  const handleAddNewItem = async () =>  {
    let randomKey = Math.random().toString();

    if(itemkey != '') {
      randomKey = itemkey;
    }
  
    if (itemName !== "" ) {
      const newItem = {
        key: randomKey,
        name: itemName,
        description: itemDescription,
        image: selectedImage,
        qty: itemQty,
        selectedFrequency: itemFrequency,
        storeName: itemStoreName,
        unit: itemUnit,
        category: itemCategory,
        isFavorite: isFavorite,
      };


      console.log('newItem  ', newItem);

      // Save the new item to AsyncStorage and your data source
      await saveData(newItem);
      if(itemkey === '') {
      localListCtx.addLocalList(newItem);
      }

      handleUpdateItem(newItem);

      // Clear the form
      setItemName("");
      setSelectedImage(null);
      setItemkey('');
      navigation.goBack();

    } else {
      alert("Please fill in all fields.");
    }
  };

  const saveData = async (newData) => {
    try {
      
      // const existingData = await loadData('groceryDataList');

      // const data = existingData ? [newData, ...existingData] : [newData];
      
      // // Save the new data to AsyncStorage
      // await AsyncStorage.setItem('groceryDataList', JSON.stringify(data));

      // Optionally, you can update your data source or context here
      //storeFavItem(newData);
      handleSubmit(newData);
    } catch (error) {
      console.error('Error saving data: ', error);
    }
  };

  function handleSubmit(product) {
    // Prepare the data to send to your server here
    console.log(' handleSubmit ');
    
    const data = new FormData();
  
    // Convert the product object to JSON and send it as a separate field
    

    data.append('name', product.name);
    data.append('qty', product.qty);
    data.append('selectedFrequency', product.selectedFrequency);
    data.append('storename', product.storeName);
    data.append('unit', product.unit);
    data.append('category', product.category);
  
   

    function dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
  
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
  
      return new Blob([ab], { type: 'image/jpeg' });
    }

    // Check if selectedImage is a URI or base64 data

    const boundary = 'gsm-boundary'; // Set your custom boundary string
    const headers = new Headers();
   
    let formDataString;
    
    if (/^data:image/.test(product.image)) {
      console.log('    product.image vase 64' + product.image);
      headers.append('Content-Type', `multipart/form-data; boundary=${boundary}`);

      data.append('imageBase64', product.image);

      formDataString = '--' + boundary + '\r\n' + [...data].map(e => 'Content-Disposition: form-data; name="' + e[0] + '"\r\n\r\n' + e[1]).join('\r\n--' + boundary + '\r\n') + '\r\n--' + boundary + '--';
      
    } else {
      console.log('    product.image ' + product.image);
      headers.append('Content-Type', `multipart/form-data`);

      // Add the image part
    data.append('image', {
      uri: product.image,
      type: 'image/jpeg', // Adjust the image type if needed
      name: 'image.jpg', // Adjust the file name if needed
    });

    formDataString = data;
    }
 


    


 

    //192.168.1.230
    let backendIP = '52.15.129.116';
    // Use the fetch API to send the data to your server
    // fetch('http://192.168.1.230:8080/product/create', {
      
    
      
    
      // fetch('http://192.168.1.230:8080/product/create', {
    fetch('https://localgroceryshop.com/product/create', {      
      method: 'POST',
      body: formDataString,
      headers,
    }).then((response) => {
        console.log('response.status '+ response.status);
        if (response.status === 200) {
          // Image uploaded successfully!
          console.log(' Product saved successfully ');
        } else {
          // Handle the response accordingly if the upload fails
          console.log(' Product saved failed ');
        }
      })
      .catch((error) => {
        // Handle any errors that occur during the fetch
        console.log(' Error : '+ error)
      });
      console.log(' handleSubmit compelete ');
   
  };

  const loadData = async (key) => {
    try {
      const value = await AsyncStorage.getItem(key);
      return value ? JSON.parse(value) : [];
    } catch (error) {
      console.error('Error loading data: ', error);
      return [];
    }
  };

  const handleCategoryChange = (value) => {
    if (value === "Other" && customCategoryInputRef?.current) {
      // If "Other" is selected, show the custom category input field
      customCategoryInputRef.current.focus();
    } else {
      // If a category is selected from the list, hide the custom category input field
      setCustomCategory("");
    }
    setItemCategory(value);
  };
  

  return (
    <ScrollView contentContainerStyle={styles.container}>      

      <Button
        title="Save Item"
        onPress={handleAddNewItem}
        style={styles.saveButton}
      />

      <View>
            <View style={{flexDirection: "row"}}>
                    <TouchableOpacity onPress={() => handleImageSelect(selectedImage)}>
                      {selectedImage ? (
                        <Image source={{ uri: selectedImage }} style={styles.image} />
                      ) : (
                        <Image source={require("../assets/noimage-icon.png")} style={styles.image} />
                      )}
                    </TouchableOpacity>
                   <View style={{flex: 1, marginLeft: 10}}>

                      <Text style={styles.label}>Name *</Text>
                      <TextInput
                        placeholder=""
                        value={itemName}
                        onChangeText={handleItemNameChange}
                        style={styles.input}
                                />
                      <Text style={styles.label}>Description (Notes) </Text>
                                <TextInput
                                  placeholder=""
                                  value={itemDescription}
                                  onChangeText={handleItemDescriptionChange}
                                  style={styles.input}
                                />

                

<Text style={styles.label}>Category</Text>
          <Picker
            selectedValue={itemCategory}
            onValueChange={(value) => handleCategoryChange(value)}
            style={styles.picker}
          >
            {possibleCategories.map((category) => (
              <Picker.Item key={category} label={category} value={category} />
            ))}
            <Picker.Item label="Other" value="Other" />
          </Picker>
          {/* Custom category input */}
          {itemCategory === "Other" && (
            <TextInput
              placeholder="Enter a custom category"
              value={customCategory}
              onChangeText={(text) => setCustomCategory(text)}
              style={styles.input}
              ref={customCategoryInputRef}
            />
          )}

                  </View>

             </View>

             <View style={styles.itemContainer}>
                  <View style={styles.column1}>
                  

                  

                  <Text style={styles.label}>Quantity</Text>
                  <TextInput
                    placeholder=""
                    value={itemQty}
                    onChangeText={handleItemQtyChange}
                    style={styles.input}
                    keyboardType="numeric" 
                  />

                {/* <Text style={styles.label}>Unit</Text>
                  <TextInput
                    placeholder=""
                    value={itemUnit}
                    onChangeText={handleItemUnitChange}
                    style={styles.input}
                  /> */}


                  <Text style={styles.label}>Unit</Text>
                  <Picker
                    selectedValue={itemUnit}
                    onValueChange={(value) => setItemUnit(value)}
                    style={styles.picker}
                  >
                    {possibleUnits.map((unit) => (
                      <Picker.Item key={unit} label={unit} value={unit} />
                    ))}
                  </Picker>




                

                  <Text style={styles.label}>Favorite</Text>
                  <Switch
                    value={isFavorite}
                    onValueChange={toggleFavorite}
                    trackColor={{ false: "#767577", true: "#81b0ff" }}
                    thumbColor={isFavorite ? "#f5dd4b" : "#f4f3f4"}
                    ios_backgroundColor="#3e3e3e"
                    style={styles.switch}
                  />
                </View>


      


                <View style={styles.column2}>
            

            

              
                    <Text style={styles.label}>Store (Shop) Name</Text>
                          <TextInput
                            placeholder=""
                            value={itemStoreName}
                            onChangeText={handleItemStoreNameChange}
                            style={styles.input}
                          />

                          
                  <Text style={styles.label}>Repeat (days)</Text>
                  <TextInput
                    placeholder=""
                    value={itemFrequency}
                    onChangeText={handleItemFrequencyChange}
                    style={styles.input}
                    keyboardType="numeric" 
                  />
                </View>

                </View>
      
        </View>
      <Button
        title="Save Item"
        onPress={handleAddNewItem}
        style={styles.saveButton}
      />

    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: "#F5F5F5",
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
    textAlign: "center",
  },
  label: {
    fontSize: 16,
    fontWeight: "bold",
  },
  input: {
    height: 40,
    width: "100%",
    borderColor: "#CCCCCC",
    borderWidth: 1,
    marginBottom: 20,
    padding: 10,
    fontSize: 16,
    borderRadius: 5,
    backgroundColor: "#FFFFFF",
  },
  saveButton: {
    width: "100%",
    backgroundColor: "#007AFF",
    padding: 15,
    borderRadius: 5,
  },
  itemContainer: {
    flexDirection: "row",
  },
  column1: {
    flex: 1,
    marginRight: 20,
  },
  column2: {
    flex: 1,
  },
  image: {
    width: 195,
    height: 220,
    marginBottom: 10,
    borderRadius: 5,
  },
  switch: {
    marginBottom: 20,
  },
  picker: {
    height: 40,
    width: '100%',
    borderColor: '#CCCCCC',
    borderWidth: 1,
    marginBottom: 20,
    fontSize: 16,
    borderRadius: 5,
    backgroundColor: '#FFFFFF',
  },
});

export default ManageFavorite;