    import React, { useReducer, useContext, useEffect  } from 'react';
    import { Text, View, StyleSheet, FlatList, Image, Button,TouchableOpacity, TextInput , ScrollView } from 'react-native';
    import {Picker} from '@react-native-picker/picker';

    import { DATA } from './data/dummy-data';
    import { FavListContext } from './store/context/favList-context';


    import AsyncStorage from '@react-native-async-storage/async-storage';
    import AsyncStorageLoader from './AsyncStorageLoader';
    import { Tooltip, colors  } from 'react-native-elements'; 

    import Ionicons from 'react-native-vector-icons/Ionicons';

    import { useNavigation } from "@react-navigation/native";
    import { fetchItems } from './util/http';
    import {  useState } from 'react';
    import { storeFavItem } from "./util/http";

    import axios from 'axios';
    import { FinalListContext } from './store/context/finallist-context';
import { LocalListContext } from './store/context/localList-contextt';


    function AllProducts({ searchText = '', source, onAddNewItemDone }) {

        const selectedItemsCtx = React.useContext(FavListContext);

        const finalListCtx = useContext(FinalListContext);

        const localListCtx = React.useContext(LocalListContext);

      const navigation = useNavigation();  
      const [showPopup, setShowPopup] = useState(false);

        const [products, setProducts] = useState([]);
        // Filter the items based on the searchText
        let filteredItems = [];
    

        const [finalList, setFinalList] = useState([]);

        const [localList, setLocalList] = useState([]);


        let mergedProducts = [];

      useEffect(() => {
        console.log('  useEffect invoked ');
        // Function to fetch data from Open Food Facts API
        mergedProducts = [];
        //localListCtx.clearLocalList();

        // Call the fetchOpenFoodData function on component mount
      // Call the fetchOpenFoodData function when searchText changes
      if (searchText) {
      // fetchUSDADB();
        fetchOpenFoodData();
      fetchProductData();

      } else {
        // If searchText is empty, you may want to clear the product list
        setProducts([]);
      }
      }, [searchText, finalList]);

      const fetchProductData = async () => {
        try {
            const encodedSearchText = encodeURIComponent(searchText);
            console.log(' encodedSearchText '+ encodedSearchText);
            
          // const finalUrl = 'http://192.168.1.230:8080/product/search?name='+encodedSearchText;
          const finalUrl = 'https://localgroceryshop.com/product/search?name='+encodedSearchText;
        
          console.log(finalUrl);
          const response = await axios.get(
            finalUrl
          );

          if (response.data) {

            const mappedProducts = response.data.map((item) => ({
                key: item.id,
                name: item.name,
                image: item.imageURL,
                qty: 1,
                visible: 'T',
                selectedFrequency: 'Daily',
                storeName: 'costco',
                unit: 'bag',
                isFavorite: false,
              }));
      
              
            // Concatenate the new products with the existing ones
            mergedProducts = mergedProducts.concat(mappedProducts);

            setProducts(mergedProducts);
      

          } else {
            console.log('No products found.');
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const fetchOpenFoodData = async () => {
        try {
            const encodedSearchText = encodeURIComponent(searchText);
            const finalUrl = `https://world.openfoodfacts.org/cgi/search.pl?search_terms=${encodedSearchText}&search_simple=1&action=process&json=1`;


          console.log(finalUrl);
          const response = await axios.get(
            finalUrl
          );

          if (response.data.products) {

        

            const mappedProducts = response.data.products.map((item) => ({
                key: item.code,
                name: item.product_name,
                image: item.image_url,
                qty: 1,
                visible: 'T',
                selectedFrequency: 'Daily',
                storeName: 'costco',
                unit: 'bag',
                isFavorite: false,
              }));

      
      
              console.log(' mappedProducts image '+ mappedProducts.image)
             
              mergedProducts = mergedProducts.concat(mappedProducts);

              // Update the state with the merged products
              setProducts(mergedProducts);

          } else {
            console.log('No products found.');
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      //USDA Food Composition Database API
      const fetchUSDADB = async () => {
        const encodedSearchText = encodeURIComponent(searchText);
        const YOUR_API_KEY = 'NogfQkjC8sr70agVHhDg5c5WpymVOit4oeMtSWsS';
      
        try {
          const response = await fetch(
            `https://api.nal.usda.gov/fdc/v1/foods/search?query=${encodedSearchText}&api_key=${YOUR_API_KEY}`
          );
      
          if (response.ok) {
            const data = await response.json();
      
            if (data.foods) {
              const mappedProducts = data.foods.map((item) => ({
                key: item.fdcId,
                name: item.description+' '+item.brandName,
                //image: `https://fdc.nal.usda.gov/api/ndb/v2/foods/${item.fdcId}/images`, // Construct image URL
                //image: 'https://fdc.nal.usda.gov/api/ndb/v2/foods/'+{item.fdcId}+'?api_key='+{YOUR_API_KEY},
                image: `https://fdc.nal.usda.gov/api/ndb/v2/foods/${item.fdcId}?api_key=${YOUR_API_KEY}`,
                qty: 1,
                visible: 'T',
                selectedFrequency: '',
                storeName: '',
                unit: '',
                isFavorite: false,
              }));
      
              // Concatenate the new products with the existing ones
              mergedProducts = mergedProducts.concat(mappedProducts);
              setProducts(mergedProducts);
            } else {
              console.log('No products found.');
            }
          } else {
            console.error('Failed to fetch data from USDA API.');
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      

      const saveData = async (newData) => {
        try {

          let existingData;
          if (source === 'ItemList') {
            existingData = await AsyncStorage.getItem('groceryDataList');
          } else {
            existingData = await AsyncStorage.getItem('groceryData');
          }     
          
          
          let data = existingData ? JSON.parse(existingData) : [];
          //data.push(newData);
          data = [newData, ...data];


          if (source === 'ItemList') {
            await AsyncStorage.setItem('groceryDataList', JSON.stringify(data));   
          } else {
            await AsyncStorage.setItem('groceryData', JSON.stringify(data));   
          }     

          
          console.log(' *********** '+data.length );
          
          //storeFavItem(newData);
          
        } catch (error) {
          console.error('Error saving data: ', error);
        }
      };

      const handleAddToList = (item) => {
        const existingItemIndex = finalListCtx.finalList.findIndex((listItem) => listItem.key === item.key);
      
        if (existingItemIndex !== -1) {
          // Item with the same key already exists, update its quantity
          finalListCtx.updateFinalList(item.key, item);
        } else {
          // Item doesn't exist in the list, add it
          finalListCtx.addFinalList(item);
          
        }  
    
      };

      function addEmptyItem() {

        console.log('--------------&&&& ****   ---------------');
        // Create an empty item object
        const emptyItem = {
          key: '' + Math.random().toString(), // Use a unique key for the empty item
          name: '',
          image: '',
          description: '',
          qty: 1, // You can set the default quantity as needed
          visible: 'T',
          selectedFrequency: '',
          storeName: '',
          unit: '',
          isFavorite: false,
        };

        console.log(' searchText  '+ searchText);
        if(searchText.length > 0) {
          emptyItem.name = searchText;
        }
        console.log(' source '+ source);
        if (source === 'ItemList') {
          
        
        finalList.push(emptyItem);
          //setFinalList((prevFinalList) => [...emptyItem, ...prevFinalList]);
          finalListCtx.addFinalList(emptyItem);
          

        } else {
  
        //   localListCtx.addLocalList(emptyItem);
        //   console.log(' localList ', localList);
        //   // setFinalList((prevFinalList) => [emptyItem, ...prevFinalList]);
        localListCtx.addLocalList(emptyItem);
    
        }

        
        
        
          
          onAddNewItemDone();
    
      }
      

     
      const handleTextChange = (key, text) => {
        console.log('key = ',key);
        console.log('text = ',text);
        
        // Find the item in your data and update its name
        const updatedItems = finalList.map((item) => {
          if (item.key === key) {
            return { ...item, name: text };
          }
          return item;
        });
        console.log('updatedItems = ',updatedItems);
        setFinalList(updatedItems);
      };


      const navigateToManageFavorite = (item) => {
        console.log('navigateToManageFavorite --', item);
        navigation.navigate("Item Details", { product: item });
      };
  
      const showPopupForDuration = () => {
        setShowPopup(true);
        setTimeout(() => {
            setShowPopup(false);
        }, 1000); // Adjust the duration as needed (3000 milliseconds = 3 seconds)
    };
   
      const handleAddToFav = async (item) =>  {
        const randomKey = Math.random().toString();

        if (item.name !== "") {
          // Create a new item object
          const newItem = {
            key: randomKey,
            name: item.name,
            image: item.image,
            category: item.category,
            qty: 1,
            visible: 'T',
            selectedFrequency: '0',
            storeName: '',
            unit: '',
            isFavorite: item.isFavorite,
          };
      
        
          
          //const favItems = await fetchItems('fav');

          console.log('Add new item to Favvv '+ newItem.name);
          // Add the new item to your existing data array
          //favItems.push(newItem);
      
          // Save the new data
          await saveData(newItem);
          // await handleAddToList(newItem);

          if (source === 'ItemList') {
            finalListCtx.addFinalList(newItem);
            showPopupForDuration();
          } else {
   
            finalListCtx.addFinalList(newItem);
            showPopupForDuration();
          }
          
          // Optionally, you can update your data storage or context here
          
          //selectedItemsCtx.addFavList(newItem);
          
          
        } else {
          alert("Please fill in all fields.");
        }
      };




      // Combine the DATA static array and existingData array
      const setShareItemState=(updatedItem) => {
        

        console.log(' = =  '+updatedItem.key);
            
            setFinalList((currentFinalList) =>
            currentFinalList.map((item) => (item.key === updatedItem.key   ? updatedItem : item))
          );
      }        

      
        if (searchText.length > 0 && products.length > 0) {
            console.log(' products.length '+ products.length);
            filteredItems = products.filter((item) => {
              const productName = item.name ? item.name.toLowerCase() : ''; // Convert to lowercase
              
              const search = searchText.toLowerCase(); // Convert to lowercase
              return (productName.includes(search));
            });
          }   

          console.log('Check localListCtx.keys lenght '+ localListCtx.keys.length); 
          if (finalList.length > 0 || localListCtx.keys.length > 0) {
            console.log('Changing filteredItems before ' + filteredItems.length);
            filteredItems = [...localListCtx.keys, ...finalList, ...filteredItems ]; // Add new items to the existing filteredItems
            console.log('Changing filteredItems after ' + filteredItems.length);
            filteredItems = filteredItems.filter((item) => {
              const productName = item.name ? item.name.toLowerCase() : ''; // Convert to lowercase
              
              const search = searchText.toLowerCase(); // Convert to lowercase
              return (productName.includes(search));
            });
            
          }
          

          filteredItems.sort((a, b) => a.name.localeCompare(b.name));

          
      
      return (
    <View>
      
    <View>
      <TouchableOpacity
        style={styles.addEmptyItemButton}
        onPress={addEmptyItem}
      >
        <Ionicons name="add" size={20} color="green" />
        <Text style={styles.addEmptyItemButtonText}>Add New Item to Catalog</Text>
      </TouchableOpacity>
      
    </View>

    <View style={styles.seperator}/>
         {/* Popup to show for a few milliseconds */}
         {showPopup && (
                <View style={styles.popup}>
                    <Text style={styles.popupText}>Item added to List!</Text>
                </View>
            )}
          <FlatList
          data={filteredItems}
          renderItem={({ item }) => <Item item={item}  onAddToList={handleAddToFav} onTextChange={handleTextChange} setShareItemState={setShareItemState} navigateToManageFavorite={navigateToManageFavorite}/>}
          keyExtractor={(item) => item.key}
        />
        
        </View>

      );
    }

      function Item({ item , onAddToList, onDeleteItem, setShareItemState, navigateToManageFavorite}) {

      
  function itemReducer(state, action) {
    switch (action.type) {
      case 'INCREMENT':
        return { ...state, qty: parseInt(state.qty) + 1 };
      case 'DECREMENT':
        return { ...state, qty: parseInt(state.qty) > 0 ? parseInt(state.qty) - 1 : parseInt(state.qty) };
      case 'UPDATE': // Handle the "UPDATE" action to change the quantity
        return { ...state, qty: action.qty };
      default:
        return state;
    }
  }

  
  const [itemState, dispatch] = useReducer(itemReducer, item);

  const [selectedFrequency, setSelectedFrequency] = useState(item.selectedFrequency);

  const updateItemValue = (type) => {
    dispatch({ type });
  };

  const handleFrequencyChange = (value) => {
    // Update the selected frequency for the item
    setSelectedFrequency(value);
  };

  const handleQuantityChange = (text) => {
    const parsedQty = parseInt(text) || 0; // Parse the input text as an integer or use 0 if it's not a valid number
    dispatch({ type: 'UPDATE', qty: parsedQty }); // Dispatch an action to update the quantity
  };

  
  const isFavorite = item.isFavorite; // Use isFavorite directly from the item
  const favListCtx = React.useContext(FavListContext);


  const toggleFavorite = (item) => {
    // Create a copy of the item with the updated isFavorite property
    const updatedItem = { ...item, isFavorite: !item.isFavorite };
  
    // Use setShareItemState to update the item
    setShareItemState(updatedItem);
  
    // Update the favorite list context
    if (updatedItem.isFavorite) {
      console.log('  addFavList '  );
      favListCtx.addFavList(updatedItem);
    } else {
      console.log('  deleteFavList ' , updatedItem);
      favListCtx.deleteFavList(updatedItem);
    }
    item.isFavorite = updatedItem.isFavorite;
  };
        
      return (
    //     <View style={{flex: 1, flexDirection: 'column'}}>
    //         <View style={{flex: 1, flexDirection: 'row', alignItems: 'center', borderBottomColor: 'black' }}>        
          
        

    //               <View style={styles.imageAndTextContainer}>
                    
    //               {/* {item.image ? (
    //   <Image
    //     style={{ width: 80, height: 80 }}
    //     source={{ uri: item.image }}
    //   />
      
    // ) : null} */}
    //               <TouchableOpacity onPress={() => navigateToManageFavorite(item)}>
                                        
    //                  <Image source={typeof item.image === 'string' ? ( item.image.length===0 ? require('../assets/noimage-icon.png') : { uri: item.image }) : item.image} style={styles.logo} />

    //               </TouchableOpacity>
                  
                
                    
                    
    // {/* <Text style={styles.listItem}>
    //   { item.name}
    // </Text> */}
    //               <TextInput
    //                       style={styles.listItem} // Define your styles for the editable input
    //                       value= { item.name}
    //                       onChangeText={(text) => onTextChange(item.key, text)}
    //                       placeholder="Edit this text"
    //                     />

                
                    
    //               </View>
                

              

    //                 <TouchableOpacity
    //         style={styles.marginALl}
    //         activeOpacity={0.7}
    //         onPress={() => onAddToFav(item)}
    //       >
          
    //       <Ionicons name="add-circle" size={30} color="green" />
    //       </TouchableOpacity>
                  
              
    //         </View>       
    //         <View style={styles.seperator}/>
    //       </View>



<View style={{flex: 1, flexDirection: 'column'}}>
<View style={styles.seperator}/>
<View style={{flex: 1, flexDirection: 'row', alignItems: 'center', borderBottomColor: 'black' }}>        

      <View style={styles.imageAndTextContainer}>
                          <View style={{flexDirection: 'row'}}>
                              <View style={{flexDirection: 'column'}}>
                                    <TouchableOpacity onPress={() => navigateToManageFavorite(item)}>                                        
                                        <Image source={typeof item.image === 'string' ? ( item.image.length===0 ? require('../assets/noimage-icon.png') : { uri: item.image }) : ((item.image)? item.image :  require('../assets/noimage-icon.png'))} style={{ ...styles.logo, marginLeft: 10 }} />
                                    </TouchableOpacity>
                                    
                                </View>   

                                <View style={{flexDirection: 'row'}}>
                                  <View style={styles.heartStyle}>
                                              <Ionicons
                                                name={item.isFavorite ? 'heart' : 'heart-outline'}
                                                size={25}
                                                color={item.isFavorite ? 'red' : 'gray'}
                                                onPress={() => toggleFavorite(item)}
                                              />
                                              
                                  </View>
                                  <View style={styles.textContainer}>
                            
                                  <TouchableOpacity onPress={() => navigateToManageFavorite(item)}>
                                          <Tooltip 
                    // Use the Tooltip component here to display the full item name
                    popover={<Text>{item.name}</Text>}
                    withOverlay={false}
                    width="auto" 
                    backgroundColor={colors.primary} >
                                                    
                                                    <Text style={{ fontWeight: 'bold', fontSize: 15 }}>{item.name.length > 20 ? item.name.slice(0, 20) + '...' : item.name}</Text>
                                              </Tooltip>
                                              </TouchableOpacity>
                                  </View>  

                                  
                            </View>
                          </View>   
                                          
                        </View>
          
                    <View>
              
                      
                       {/**
                      <View>
                       
                      <Text style={styles.qtyUnitStyle}>{item.unit}</Text> 
                      </View>
                       */}
               
          
    {/*
            <View style={{flex:1, flexDirection: 'row-reverse'}}>                 

              <View style={styles.container}>
                <Image source={require("../assets/addItem.png")} style={styles.tinyLogo}></Image>         
                </View>        
            </View>
            */}
            {/** <FavIcon/>  */}

            {/** 
            <View>
              <View style={styles.imagekerContainer}>
                <Picker
                  selectedValue={selectedFrequency}
                  style={{ height: 10, width: 150, marginBottom: 13 }}
                  onValueChange={handleFrequencyChange}
                >
                  <Picker.Item label="Daily" value="Daily" />
                  <Picker.Item label="Weekly" value="Weekly" />
                  <Picker.Item label="Quarterly" value="Quarterly" />
                  <Picker.Item label="Yearly" value="Yearly" />
                </Picker>
                
              </View>
              </View>  
              */}
      </View>

      <View style={styles.buttonAddToList}>
        {/* <Button title="Add to List" color={'#87CEFA'}> </Button> 
        <Button title='Add To List' onPress={() => onAddToList(itemState)} style={styles.addToListStyle}> 
            
        </Button>
        */}
          {/* <TouchableOpacity
            onPress={() => onAddToList(itemState)}
            style={styles.customButton}
          >
            <Text style={styles.buttonText}>Add To List</Text>
          </TouchableOpacity> */}

          <TouchableOpacity
            style={styles.marginALl}
            activeOpacity={0.7}
            onPress={() => onAddToList(itemState)}
          >
          
          <Ionicons name="add-circle" size={30} color="green" />
          </TouchableOpacity>

      </View>
  
</View>       

</View>












      );
    }


    

    const styles = StyleSheet.create({
      editableItem: {
        width: '80%',
        height: 30,
        fontSize: 13,
        textAlign: 'left',
      },
        secondColumnContainerParent: {
          flexDirection: 'column',     
          
        },
        addToListStyle: {
          borderCurve: true,
          borderColor: 'black',

        }
        ,
        textContainer: {
          flex: 1, // Take up available space
          width: '70%', // Adjust the width to your preference
          marginRight: 10, // Add margin for spacing
          marginLeft: 10,
        },
    heartStyle: {
      marginLeft: 10,
    },
        imageAndTextContainer: {
          marginLeft: 5,
          flex: 1, 
          flexDirection: 'column',
          justifyContent: 'center',
          flexDirection: 'row',
          justifyContent: 'left',
          width: '25%',
          padding: 2,
          
        },
        secondColumnContainer: {
          flex: 1, 
          flexDirection: 'column',
          justifyContent: 'left',
          width: '20%',
          marginLeft: 5

        },
        tinyLogo: {
          width: 23,
          height: 23,
        },
        tinytinyLogo: {
          width: 20,
          height: 20,
        },
        logo: {
          width: 68,
          height: 68,
        },
        listItem: {        
            width: '90%',
            height: 40 ,        
            fontSize: 18,      
            textAlign: 'left',    
            paddingLeft: 10,

        },
        countCounter: {
          flexDirection: 'row',      
          
        },
        qtyStyle: {
                borderWidth: 0.5,
                width: 50,
                marginLeft: 10 ,  
                marginRight: 10, 
                textAlign: 'center',
                alignContent: 'center'
        },
        qtyUnitStyle: {
          borderWidth: 0,
          alignContent: 'center',
          width: 110,     
          fontSize: 13,      
          textAlign: 'center'
    },
        seperator: {
          width: '100%',
          height: 1,
          backgroundColor: 'gray',
          marginBottom: 5,
          
        },
        buttonAddToList: {
          
          width: '25%',
          justifyContent: 'center',
          marginRight: 2,
          marginLeft: 5,
          flexDirection: 'row',     
          
          alignItems: 'center',
          borderRadius: 5, 
        },
        buttonRemoveFromList: {
          marginTop: 3,
        },
        marginALl: {
          marginRight: 5
        },
      
        pickerContainer: {
          flexDirection: 'column',
          alignItems: 'left',      
        },
        customButton: {
          backgroundColor: '#87CEFA', // Background color for the button
          paddingVertical: 10,         // Adjust the button's vertical padding
          paddingHorizontal: 10,       // Adjust the button's horizontal padding
          borderRadius: 5,            // Add rounded corners to the button
        },
        addEmptyItemButton: {
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: '#4CAF50',
          padding: 10,
          borderRadius: 5,
          marginBottom: 10,
          alignSelf: 'center',
        },
        addEmptyItemButtonText: {
          fontSize: 18,
          color: 'white',
          marginLeft: 10,
        },
        popup: {
          position: 'absolute',
          top: 10,
          right: 10,
          backgroundColor: 'green',
          padding: 10,
          borderRadius: 5,
          zIndex: 999, // Adjust the zIndex to ensure it's displayed on top
      },
  
      popupText: {
          color: 'white',
      },
      });

    export default AllProducts;


